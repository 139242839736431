define("ember-animated/templates/components/animated-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PNE/U3xi",
    "block": "{\"symbols\":[\"Tag\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[23,0,[\"tag\"]]],null]],[[\"tagName\"],[[23,0,[\"tag\"]]]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",[29,[\"animated-container \",[23,2,[]]]]],[13,3]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,4],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-container.hbs"
    }
  });

  _exports.default = _default;
});