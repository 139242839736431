define("ember-svg-jar/inlined/monroney-label-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path d=\"M16.469 21.972c-.3.031-1.805.031-2.105.031-.301 0-1.805 0-2.106-.031-.15-.157-.3-.472-.3-.628 0-.158.3-.628.45-.628l.45.008c.449.01 1.116.023 1.506.023.393 0 1.06-.014 1.508-.023l.447-.008c.15 0 .45.47.45.628 0 .156-.15.471-.3.628zM11.356 18.784c.167-.639.458-1.322.588-1.614a.19.19 0 01.134-.118c.302-.06 1.133-.2 2.286-.2s1.982.14 2.286.2c.057.012.105.053.133.118.13.292.42.975.589 1.614h-6.016zM18.454 19.589c.14.07.149.423 0 .483-.2.08-1.042.08-1.203 0-.14-.07-.14-.413 0-.483.16-.08 1.043-.08 1.203 0zM11.475 19.589c.14.07.14.413 0 .483-.16.08-1.042.08-1.203 0-.14-.07-.14-.413 0-.483.16-.08 1.042-.08 1.203 0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.166 7.167v25.667h25.667V7.167H7.166zM9.499 9.5V13h21V9.5h-21zm0 17.5v3.5h21V27h-21zm9.476-8.216c.111 0 .201-.096.201-.215v-.214a.214.214 0 00-.137-.203l-.602-.215a.187.187 0 00-.18.03.218.218 0 00-.084.174v.214h-.25c-.18-.644-.53-1.352-.682-1.644a.595.595 0 00-.428-.32 15.155 15.155 0 00-2.45-.182 15.17 15.17 0 00-2.45.182.594.594 0 00-.426.32c-.153.292-.504 1-.683 1.644h-.25v-.215a.22.22 0 00-.084-.174.19.19 0 00-.18-.03l-.602.216a.212.212 0 00-.137.203v.215c0 .118.09.214.2.214h.632l-.553.474a.202.202 0 00-.048.057l-.01.02c-.023.05-.22.514-.22 1.38v2.361c0 .342.24.859.6.859h.803c.361 0 .601-.517.601-.859v-.04c.207.025.411.04.602.04h4.411c.192 0 .396-.015.602-.04v.04c0 .342.24.859.602.859h.801c.362 0 .602-.517.602-.859v-2.36c0-.888-.207-1.352-.221-1.383a.225.225 0 00-.057-.075l-.553-.474h.63zm3.259 1.993h-1.426V19.35h1.426v1.426zm0 2.851h-1.426v-1.426h1.426v1.426zm0-5.702h-1.426V16.5h1.426v1.426zm8.265 2.85h-6.84v-1.425h6.84v1.426zm0 2.852h-6.84v-1.426h6.84v1.426zm-6.84-5.702V16.5h6.84v1.426h-6.84z\" fill=\"#000\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});