define("ember-animated/-private/margin-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.collapsedChildren = collapsedChildren;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // takes an element, the element's computed style, and either 'top' or
  // 'bottom'.
  //
  // returns list of Element for increasingly deep descendants
  // of the given element whose top (or bottom) margin collapses with
  // the given element's.
  function collapsedChildren(element, cs, which) {
    var children = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

    // margin collapse only happens if we have no border or padding
    if (isBlock(cs) && cs.getPropertyValue("border-".concat(which, "-width")) === '0px' && cs.getPropertyValue("padding-".concat(which)) === '0px') {
      var block;

      if (which === 'top') {
        block = firstChildBlock(element);
      } else {
        block = lastChildBlock(element);
      }

      if (block) {
        var _block = block,
            _block2 = _slicedToArray(_block, 2),
            child = _block2[0],
            childCS = _block2[1];

        children.push(child);
        collapsedChildren(child, childCS, which, children);
      }
    }

    return children;
  }

  function firstChildBlock(element) {
    for (var i = 0; i < element.children.length; i++) {
      var child = element.children[i];
      var childCS = getComputedStyle(child);

      if (childCS.clear !== 'none') {
        // an intervening block with clearance prevents margin collapse
        return;
      }

      if (isBlock(childCS)) {
        return [child, childCS];
      }
    }

    return;
  }

  function lastChildBlock(element) {
    for (var i = element.children.length - 1; i >= 0; i--) {
      var child = element.children[i];
      var childCS = getComputedStyle(child);

      if (childCS.clear !== 'none') {
        // an intervening block with clearance prevents margin collapse
        return;
      }

      if (isBlock(childCS)) {
        return [child, childCS];
      }
    }

    return;
  }

  function isBlock(cs) {
    return cs.display === 'block' && (cs.position === 'static' || cs.position === 'relative') && cs.getPropertyValue('float') === 'none' && cs.overflow === 'visible';
  }
});