define("ember-svg-jar/inlined/world-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.994 0A5.997 5.997 0 000 6c0 3.312 2.682 6 5.994 6A6.003 6.003 0 0012 6c0-3.312-2.688-6-6.006-6zm4.158 3.6h-1.77a9.39 9.39 0 00-.828-2.136A4.818 4.818 0 0110.152 3.6zM6 1.224c.498.72.888 1.518 1.146 2.376H4.854A8.452 8.452 0 016 1.224zM1.356 7.2A4.945 4.945 0 011.2 6c0-.414.06-.816.156-1.2h2.028A9.91 9.91 0 003.3 6c0 .408.036.804.084 1.2H1.356zm.492 1.2h1.77c.192.75.468 1.47.828 2.136A4.792 4.792 0 011.848 8.4zm1.77-4.8h-1.77a4.792 4.792 0 012.598-2.136A9.39 9.39 0 003.618 3.6zM6 10.776A8.452 8.452 0 014.854 8.4h2.292A8.452 8.452 0 016 10.776zM7.404 7.2H4.596A8.828 8.828 0 014.5 6c0-.408.042-.81.096-1.2h2.808c.054.39.096.792.096 1.2 0 .408-.042.804-.096 1.2zm.15 3.336A9.39 9.39 0 008.382 8.4h1.77a4.818 4.818 0 01-2.598 2.136zM8.616 7.2A9.91 9.91 0 008.7 6a9.91 9.91 0 00-.084-1.2h2.028c.096.384.156.786.156 1.2 0 .414-.06.816-.156 1.2H8.616z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});