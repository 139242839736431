define("ember-svg-jar/inlined/no-aftermarket-devices-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#00EDED\" fill-opacity=\".15\" d=\"M0 0h56v56H0z\"/><path d=\"M43.333 34.934c.369 0 .667-.32.667-.713v-.708a.708.708 0 00-.457-.675l-1.999-.711a.624.624 0 00-.6.099.721.721 0 00-.278.576v.711h-.831c-.597-2.134-1.761-4.48-2.27-5.447a1.976 1.976 0 00-1.42-1.06C34.802 26.781 31.95 26.4 28 26.4c-3.951 0-6.8.38-8.145.603-.601.1-1.126.49-1.42 1.06-.507.968-1.673 3.314-2.27 5.448h-.831V32.8a.726.726 0 00-.278-.576.634.634 0 00-.6-.099l-2 .713a.703.703 0 00-.456.673v.711c0 .392.298.712.667.712h2.1l-1.84 1.569a.666.666 0 00-.16.188l-.036.07c-.073.164-.731 1.7-.731 4.571v2.083l6.77.014 1.897-.014h16.666l6.667.014V41.334c0-2.979-.708-4.52-.737-4.586a.732.732 0 00-.188-.245l-1.84-1.57h2.098zM36 43.414H20c0-.52 1-2.08 1.5-2.08s4.549.104 6.5.104c1.968 0 6-.104 6.5-.104s1.5 1.56 1.5 2.08zm-18-8.48c.557-2.118 1.523-4.38 1.956-5.349.091-.21.256-.355.445-.39 1.005-.2 3.766-.661 7.599-.661 3.834 0 6.592.463 7.601.662.19.036.348.175.443.39.433.968 1.399 3.23 1.957 5.348H17.998 18zM41.6 37.6c.467.232.495 1.402 0 1.6-.666.267-3.466.267-4 0-.466-.232-.466-1.368 0-1.6.534-.267 3.467-.267 4 0zm-23.204 0c.468.232.468 1.368 0 1.6-.53.267-3.465.267-3.999 0-.466-.232-.466-1.368 0-1.6.534-.267 3.467-.267 4.001 0h-.002zM17.734 16.88l1.867 1.867c4.639-4.639 12.161-4.639 16.8 0l1.867-1.867c-5.666-5.665-14.859-5.665-20.534 0zm3.734 3.734l1.866 1.866a6.602 6.602 0 019.334 0l1.866-1.866c-3.602-3.603-9.454-3.603-13.066 0z\" fill=\"#00EDED\"/>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});