define("@loom/ember-dropdown/templates/components/dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I2/aS/Bh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[24,[\"publicAPI\",\"uniqueId\"]],[24,[\"publicAPI\",\"isOpen\"]],[24,[\"publicAPI\",\"disabled\"]],[24,[\"publicAPI\",\"actions\"]],[28,\"component\",[[24,[\"triggerComponent\"]]],[[\"palette\",\"shade\",\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[[24,[\"palette\"]],[24,[\"shade\"]],[28,\"readonly\",[[24,[\"publicAPI\"]]],null],[28,\"readonly\",[[24,[\"hPosition\"]]],null],[28,\"action\",[[23,0,[]],\"handleFocus\"],null],[28,\"readonly\",[[24,[\"renderInPlace\"]]],null],[28,\"readonly\",[[24,[\"vPosition\"]]],null]]]],[28,\"component\",[[24,[\"contentComponent\"]]],[[\"palette\",\"shade\",\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[24,[\"palette\"]],[24,[\"shade\"]],[28,\"readonly\",[[24,[\"publicAPI\"]]],null],[28,\"readonly\",[[24,[\"hPosition\"]]],null],[28,\"readonly\",[[24,[\"renderInPlace\"]]],null],[28,\"readonly\",[[24,[\"preventScroll\"]]],null],[28,\"readonly\",[[24,[\"rootEventType\"]]],null],[28,\"readonly\",[[24,[\"vPosition\"]]],null],[28,\"readonly\",[[24,[\"destination\"]]],null],[28,\"readonly\",[[24,[\"top\"]]],null],[28,\"readonly\",[[24,[\"left\"]]],null],[28,\"readonly\",[[24,[\"right\"]]],null],[28,\"readonly\",[[24,[\"width\"]]],null],[28,\"readonly\",[[24,[\"height\"]]],null],[28,\"readonly\",[[24,[\"otherStyles\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-dropdown/templates/components/dropdown-menu.hbs"
    }
  });

  _exports.default = _default;
});