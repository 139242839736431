define("@loom/ember-chrome/templates/components/app-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3x6l3Qay",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"section\"],[[28,\"component\",[\"app-header-section\"],[[\"header\",\"palette\",\"shade\",\"align\"],[[23,0,[]],[24,[\"palette\"]],[24,[\"shade\"]],[24,[\"align\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-chrome/templates/components/app-header.hbs"
    }
  });

  _exports.default = _default;
});