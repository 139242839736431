define("ember-modifier/-private/functional/modifier", ["exports", "ember-modifier/-private/functional/modifier-manager"], function (_exports, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = modifier;
  var MANAGERS = new WeakMap();

  function managerFor(owner) {
    var manager = MANAGERS.get(owner);

    if (manager === undefined) {
      manager = new _modifierManager.default(owner);
    }

    return manager;
  }

  function modifier(fn) {
    return Ember._setModifierManager(managerFor, fn);
  }
});