define("ember-svg-jar/inlined/driver-vehicle-safety-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#00EDED\" fill-opacity=\".15\" d=\"M0 0h56v56H0z\"/><path d=\"M28 12c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zm0 3.2c5.963 0 10.973 4.077 12.396 9.596a25.637 25.637 0 00-24.792 0C17.027 19.277 22.037 15.2 28 15.2zm-3.307 25.168a12.83 12.83 0 01-8.411-7.21 6.404 6.404 0 018.41 7.21h.001zM28 31.2a3.2 3.2 0 110-6.4 3.2 3.2 0 010 6.4zm3.307 9.168a6.404 6.404 0 018.41-7.21 12.828 12.828 0 01-8.41 7.21z\" fill=\"#00EDED\"/>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});