define("@loom/ember-styles/macros/layouts", ["exports", "@loom/ember-macros/macros/computed-option", "@loom/ember-styles/utils/layout-options"], function (_exports, _computedOption, _layoutOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.size = size;
  _exports.paddingCls = paddingCls;
  _exports.marginCls = marginCls;
  _exports.align = align;
  _exports.alignCls = alignCls;

  function size() {
    var def = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'none';
    var allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _layoutOptions.PADDING_SIZES;
    return (0, _computedOption.default)(def, allowed);
  }

  var sizesDefault = {
    left: true,
    top: true,
    right: true,
    bottom: true
  };

  var sizeCls = function sizeCls(prefix, sizeProp, sides) {
    var getSides = typeof sides === 'string' ? function (context) {
      return Ember.get(context, sides);
    } : function () {
      return sides;
    };
    return Ember.computed(sizeProp, {
      get: function get() {
        var sizeVal = Ember.get(this, sizeProp);

        var _ref = getSides(this) || sizesDefault,
            _ref$left = _ref.left,
            left = _ref$left === void 0 ? false : _ref$left,
            _ref$top = _ref.top,
            top = _ref$top === void 0 ? false : _ref$top,
            _ref$right = _ref.right,
            right = _ref$right === void 0 ? false : _ref$right,
            _ref$bottom = _ref.bottom,
            bottom = _ref$bottom === void 0 ? false : _ref$bottom;

        if (left && top && right && bottom) {
          return "".concat(prefix, "--").concat(sizeVal);
        }

        var classes = [];

        if (left && right) {
          classes.push("".concat(prefix, "x--").concat(sizeVal));
        } else if (left) {
          classes.push("".concat(prefix, "l--").concat(sizeVal));
        } else if (right) {
          classes.push("".concat(prefix, "r--").concat(sizeVal));
        }

        if (top && bottom) {
          classes.push("".concat(prefix, "y--").concat(sizeVal));
        } else if (top) {
          classes.push("".concat(prefix, "t--").concat(sizeVal));
        } else if (bottom) {
          classes.push("".concat(prefix, "b--").concat(sizeVal));
        }

        return classes.join(' ');
      }
    });
  };

  function paddingCls() {
    var sizeProp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'padding';
    var sides = arguments.length > 1 ? arguments[1] : undefined;
    return sizeCls('p', sizeProp, sides);
  }

  function marginCls() {
    var sizeProp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'margin';
    var sides = arguments.length > 1 ? arguments[1] : undefined;
    return sizeCls('m', sizeProp, sides);
  }

  function align() {
    var def = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'left';
    var allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _layoutOptions.ALIGN_OPTS;
    return (0, _computedOption.default)(def, allowed);
  }

  function alignCls() {
    var alignProp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'align';
    return Ember.computed(alignProp, {
      get: function get() {
        return "text--".concat(Ember.get(this, alignProp));
      }
    });
  }
});