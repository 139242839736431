define("ember-svg-jar/inlined/check-engine-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.746 33.776V17.777h2.047v-3.494H33.6v3.245h4.076v6.44h2.805v-1.411h3.441v7.94H40.64v-1.642h-3.387v6.67H22.854l-4.076-1.694-9.032-.055zM4.063 25.962h4.872M4.063 18.675v13.621M22.175 9.21v4.872\" stroke=\"#424B55\" stroke-width=\"1.92\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M34.734 9.21H11.258\" stroke=\"#424B55\" stroke-width=\"2.007\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});