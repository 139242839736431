define("ember-svg-jar/inlined/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.545 8.88L1.324 5.66.25 6.732l4.295 4.295 9.205-9.204L12.676.75l-8.13 8.13z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "14",
      "height": "12",
      "viewBox": "0 0 14 12",
      "fill": "none"
    }
  };
  _exports.default = _default;
});