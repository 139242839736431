define("ember-svg-jar/inlined/seatbelt-status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h24v24H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.25 5.25a2.25 2.25 0 01-1.43 2.096c.408.096.787.263 1.125.487-1.538 1.6-3.435 3.329-5.293 4.635l-.156-1.249a3.533 3.533 0 012.683-3.873A2.25 2.25 0 1114.25 5.25zm-5.358 9.136c-1.227.2-2.366.57-3.296 1.19L5 14.384c1.113-.318 2.373-1.017 3.652-1.916l.239 1.906.001.012zm6.21.038a.996.996 0 01-.152.38c1.55.891 1.55 2.909 1.55 6.446h-2c0-4.54 0-5.045-2.5-5.045s-2.5.504-2.5 5.045h-2c0-3.537 0-5.555 1.55-6.445a.997.997 0 01-.158-.419c2.012-.328 4.26-.198 6.21.038zm.152-1.208c.97.126 1.834.284 2.506.407.385.07.707.13.95.165v1.192l-.507-.086a63.742 63.742 0 00-3.101-.47.905.905 0 00.008-.05l.144-1.158zm0 0c-1.656-.214-3.624-.334-5.485-.024 1.902-1.268 3.635-2.873 5.11-4.456.492.691.74 1.564.625 2.483l-.25 1.997zm-.375-4.48a3.556 3.556 0 00-.934-.903c1.372-1.429 2.459-2.754 2.977-3.583l1.192.596a47.935 47.935 0 01-3.235 3.89z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});