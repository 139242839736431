define("ember-svg-jar/inlined/low-device-damage-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"path-1\" d=\"M0 0h32v32H0z\"/></defs><g id=\"Landing-Page\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g id=\"Landing-Page-Copy-30\" transform=\"translate(-1025 -1291)\"><g id=\"Our-solution-benefits\" transform=\"translate(120 1046)\"><g id=\"Low_Device_Damage_Icon\" transform=\"translate(902 241)\"><mask id=\"mask-2\" fill=\"#fff\"><use xlink:href=\"#path-1\"/></mask><g id=\"screen_lock_rotation-24px\" mask=\"url(#mask-2)\"><g transform=\"translate(1 3)\"><path d=\"M27.462 16.306l-3.125-3.08-1.714 1.69 2.7 2.66-6.882 6.784L4.678 10.792l6.881-6.784 2.553 2.517 1.715-1.69L12.848 1.9a1.831 1.831 0 00-2.577 0L2.538 9.522a1.77 1.77 0 000 2.54L17.152 26.47a1.831 1.831 0 002.577 0l7.733-7.623a1.77 1.77 0 000-2.541zm-8.815-4.519h6.08a1.21 1.21 0 001.215-1.198V5.794a1.21 1.21 0 00-1.216-1.198v-.6c0-1.654-1.361-2.996-3.04-2.996-1.677 0-3.039 1.342-3.039 2.996v.6a1.21 1.21 0 00-1.215 1.198v4.795c0 .659.547 1.198 1.215 1.198zm.973-7.79c0-1.127.924-2.038 2.067-2.038 1.143 0 2.067.91 2.067 2.037v.6H19.62v-.6z\" id=\"Shape\" fill=\"#000\" fill-rule=\"nonzero\"/><path id=\"Path\" fill=\"none\" d=\"M0 0h29v29H0z\"/></g></g></g></g></g></g>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});