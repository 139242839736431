define("@loom/ember-dropdown/components/dropdown-content-item", ["exports", "@loom/ember-dropdown/templates/components/dropdown-content-item", "@loom/ember-styles/macros/palettes"], function (_exports, _dropdownContentItem, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dropdown Content Item
   * @description This is the inner component of the Dropdown Content component.
   * It is each item in the dropdown menu.
   */
  var _default = Ember.Component.extend({
    layout: _dropdownContentItem.default,
    classNames: ['dropdown-menu__content__item'],
    classNameBindings: ['paletteCls'],
    tagName: 'li',

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the background for the dropdown content item. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the background of the
     * dropdown menu content item. Defaults to `base`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('base'),
    paletteCls: (0, _palettes.paletteCls)()
  });

  _exports.default = _default;
});