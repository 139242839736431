define("ember-svg-jar/inlined/embedded-solution-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"path-embedded-solution-icon\" d=\"M0 3h32v32H0z\"/></defs><g id=\"Landing-Page\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g id=\"Landing-Page-Copy-30\" transform=\"translate(-704 -1115)\"><g id=\"Our-solution-benefits\" transform=\"translate(120 1046)\"><g id=\"Embedded_Solution_Icon\" transform=\"translate(580 62)\"><mask id=\"mask-2\" fill=\"#fff\"><use xlink:href=\"#path-embedded-solution-icon\"/></mask><g id=\"Group-15\" mask=\"url(#mask-2)\" fill=\"#000\" fill-rule=\"nonzero\"><g transform=\"translate(4)\"><path d=\"M18.111 21c.733 0 1.356.462 1.578 1.11L22 28.7v3.299h-2.317c.046-.129.076-.265.089-.407l.006-.142c0-.913-.745-1.65-1.667-1.65a1.656 1.656 0 00-1.572 2.2H7.46c.061-.172.095-.357.095-.55 0-.913-.745-1.65-1.667-1.65a1.656 1.656 0 00-1.572 2.2L2 31.998v-3.299l2.311-6.588A1.67 1.67 0 015.89 21zm0 1.65H5.89L4.222 27.6h15.556l-1.667-4.95z\" id=\"Combined-Shape\"/><path d=\"M3.5 4.267v3.018c7.641 0 13.837 6.08 13.837 13.579h3.076c0-9.163-7.576-16.597-16.913-16.597zm0 6.037v3.019c4.24 0 7.685 3.381 7.685 7.541h3.076c0-5.835-4.815-10.56-10.761-10.56z\" id=\"Shape\" transform=\"rotate(-45 11.957 12.565)\"/></g></g></g></g></g></g>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});