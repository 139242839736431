define("ember-svg-jar/inlined/geo-fence-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.564 25.556a5.787 5.787 0 001.527-3.92c0-.247-.03-.487-.058-.727h1.476a7.275 7.275 0 01-7.236 8A7.275 7.275 0 0110 21.637a7.275 7.275 0 019.454-6.939V18h-2.909v2.182h-2.181v1.455h5.09v2.909h2.11v1.01zm-10.11-3.92a5.81 5.81 0 005.091 5.768V26h-1.454v-2.182l-3.484-3.483a5.9 5.9 0 00-.152 1.302z\" fill=\"#0C0C0C\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M29.636 17.757h-.97v.97h.97v-.97zm-.97-1.939h.97v.97h-.97v-.97zm0-1.94h.97v.97h-.97v-.97zM21.88 11.94h-.97v.97h.97v-.97zm0-.97h-.97V10h.97v.97zm7.757.97h-.97v.97h.97v-.97zm-2.909 5.818h.97v.97h-.97v-.97zM24.787 10h.97v.97h-.97V10zm1.94 0h.97v.97h-.97V10zm2.91 0h-.97v.97h.97V10zm-3.88 8.727H20.91V13.88h.97v3.878h3.879v.97z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});