define("@loom/ember-text/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncateText = truncateText;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var ELLIPSIS = '…';

  function truncateText() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [],
        _ref2 = _slicedToArray(_ref, 2),
        str = _ref2[0],
        len = _ref2[1];

    if (str && str.length > len) {
      return str.slice(0, Math.max(len, ELLIPSIS.length + 1) - ELLIPSIS.length) + ELLIPSIS;
    }

    return str;
  }

  var _default = Ember.Helper.helper(truncateText);

  _exports.default = _default;
});