define("ember-svg-jar/inlined/close-video-modal-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.5 4.01L15.99 2.5 10 8.49 4.01 2.5 2.5 4.01 8.49 10 2.5 15.99l1.51 1.51L10 11.51l5.99 5.99 1.51-1.51L11.51 10l5.99-5.99z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});