define("ember-svg-jar/inlined/select-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 0l5 5 5-5H0z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "10",
      "height": "5",
      "viewBox": "0 0 10 5"
    }
  };
  _exports.default = _default;
});