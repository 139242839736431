define("@loom/ember-buttons/components/button-link", ["exports", "@loom/ember-buttons/components/button-basic"], function (_exports, _buttonBasic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
   * @class Button Link
   * @description This component inherits from `{{button-basic}}`. It renders a button
   * using an `<a>` tag, instead of `<button>`. It takes the same arguments as
   * `{{link-to}}` for the URL, and supports all the same options as `{{button-basic}}`.
    ```handlebars
    {{button-link 'photos.edit' photo
      text='Edit Photo'
      palette='secondary'
      shade='base'
      icon='svg-add'}}
    ```
    See the docs on `{{button-basic}}` for more customization options.
   */
  var ButtonLinkComponent = _buttonBasic.default.extend({
    tagName: 'a',
    router: Ember.inject.service(),
    attributeBindings: ['_href:href'],
    type: null,
    click: function click() {},
    _href: Ember.computed('params', function () {
      var _EmberGet;

      var params = Ember.get(this, 'params') || [];
      return params.length > 0 ? (_EmberGet = Ember.get(this, 'router')).urlFor.apply(_EmberGet, _toConsumableArray(params)) : null;
    })
  }).reopenClass({
    positionalParams: 'params'
  });

  var _default = ButtonLinkComponent;
  _exports.default = _default;
});