define("@loom/ember-chrome/components/app-sidebar", ["exports", "@loom/ember-styles/macros/bem-class", "@loom/ember-chrome/templates/components/app-sidebar", "@loom/ember-chrome/components/app-chrome", "@loom/ember-styles/macros/palettes"], function (_exports, _bemClass, _appSidebar, _appChrome, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class App Sidebar
   * @description An optional high level component to be rendered inside of `{{appChrome}}`. All attributes are 
   * available to any component rendered inside of it through the yielded `appChrome` object. `{{appSidebar}}` 
   * should be rendered as a sibling of `{{appBody}}`.
    Fixed Example:
    ```handlebars
    {{#app-chrome as |appChrome|}}
      {{#appChrome.sideBar palette='black' type='fixed' as |sideBar|}}
        {{#sideBar.header}}
          {{!-- Logo Or Whatever --}}
        {{/sideBar.header}}
  
        {{#sideBar.body}}
          {{!-- Navigation Or Whatever. --}}
        {{/sideBar.body}}
  
        {{#sideBar.footer}}
          {{!-- Copyright Or Whatever --}}
        {{/sideBar.footer}}
      {{/appChrome.sideBar}}
    {{/app-chrome}}
    ```
    Off Canvas Example:
    ```handlebars
    {{#app-chrome as |appChrome|}}
      {{#appChrome.sideBar palette='black' type='off-canvas' as |sideBar|}}
        {{#sideBar.header}}
          {{!-- Logo Or Whatever --}}
        {{/sideBar.header}}
  
        {{#sideBar.body}}
          {{!-- Navigation Or Whatever. This area can scroll if too long, and will 
            push the header to the top and footer to the bottom --}}
        {{/sideBar.body}}
  
        {{#sideBar.footer}}
          {{!-- Copyright Or Whatever --}}
        {{/sideBar.footer}}
      {{/appChrome.sideBar}}
  
      {{#appChrome.appBody}}
        {{#appChrome.appHeader as |appHeader|}}
          {{#appHeader.section align='left'}}
            <button class='btn btn--regular btn--transparent' {{action appChrome.openSideBar}}>
              {{inline-svg 'menu' class='icon icon--menu'}}
            </button>
          {{/appHeader.section}}
        {{/appChrome.appHeader}}
      {{/appChrome.appBody}}
    {{/app-chrome}}
    ```
   */
  var _default = Ember.Component.extend({
    layout: _appSidebar.default,
    tagName: 'aside',
    hook: 'app-sidebar',
    classNames: ['app-sidebar'],
    classNameBindings: ['paletteCls', 'typeCls', 'openCls', 'collapsedCls'],

    /**
     * @argument type
     * @description `fixed || off-canvas`. A sidebar with `type='off-canvas'` will show and hide
     * itself on the left side of the screen, and a button is needed somewhere in the application
     * to open the sidebar. Optional. Defaults to `off-canvas`.
     * @type {string}
     */
    type: 'off-canvas',

    /**
     * @private
     */
    isOpen: false,

    /**
     * @private
     */
    isCollapsed: false,

    /**
     * @private
     */
    hasHeader: false,

    /**
     * @private
     */
    hasFooter: false,

    /**
     * @private
     */
    hasBody: false,

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the body of chrome. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the body of chrome. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark'),
    paletteCls: (0, _palettes.paletteCls)(),
    typeCls: (0, _bemClass.default)('app-sidebar', 'modifier', 'type'),
    openCls: (0, _bemClass.default)('app-sidebar--is-open', 'modifier', 'isOpen'),
    collapsedCls: (0, _bemClass.default)('app-sidebar--is-collapsed', 'modifier', 'isCollapsed'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (false && !(this.appChrome instanceof _appChrome.default) && Ember.assert('app-sidebar must be rendered inside of an app-chrome', this.appChrome instanceof _appChrome.default));
      this.appChrome.onContentAdded('sidebar', {
        type: this.type
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'appChrome.hasSidebar')) {
        Ember.set(this.appChrome, 'sideBarType', this.type);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.sideBar) {
        this.appChrome.onContentRemoved('sidebar');
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.appChrome) {
        Ember.set(this.appChrome, 'sideBarType', null);
      }
    },

    /**
     * @private
     */
    onContentAdded: function onContentAdded(type) {
      if (type === 'header') {
        (false && !(!this.hasHeader) && Ember.assert('Side Bar can only have one header', !this.hasHeader));
        this.hasHeader = true;
      } else if (type === 'footer') {
        (false && !(!this.hasFooter) && Ember.assert('Side Bar can only have one footer', !this.hasFooter));
        this.hasFooter = true;
      } else if (type === 'body') {
        (false && !(!this.hasBody) && Ember.assert('Side Bar can only have one body', !this.hasBody));
        this.hasBody = true;
      } else {
        (false && !(false) && Ember.assert("unknown sidebar content type \"".concat(type, "\"")));
      }
    },

    /**
     * @private
     */
    onContentRemoved: function onContentRemoved(type) {
      if (type === 'header') {
        this.hasHeader = false;
      } else if (type === 'footer') {
        this.hasFooter = false;
      } else if (type === 'body') {
        this.hasBody = false;
      } else {
        (false && !(false) && Ember.assert("unknown sidebar content type \"".concat(type, "\"")));
      }
    },
    click: function click() {
      if (Ember.get(this, 'isOpen')) {
        Ember.set(this, 'isOpen', false);
      }
    }
  });

  _exports.default = _default;
});