define("ember-svg-jar/inlined/tire-pressure-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10 20c0-5.52 4.48-10 10-10s10 4.48 10 10-4.48 10-10 10-10-4.48-10-10zm4.75 0A5.243 5.243 0 0020 25.25 5.243 5.243 0 0025.25 20 5.243 5.243 0 0020 14.75 5.243 5.243 0 0014.75 20z\" fill=\"#0C0C0C\"/><path d=\"M19.25 23.3h1.4v-1.4h-1.4v1.4zM19.25 17v3.5h1.4V17h-1.4z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});