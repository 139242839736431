define("@loom/ember-buttons/components/button-group", ["exports", "@loom/ember-buttons/templates/components/button-group", "@loom/ember-styles/macros/bem-class", "@loom/ember-macros/macros/computed-option", "@loom/ember-styles/utils/layout-options", "@loom/ember-styles/macros/layouts"], function (_exports, _buttonGroup, _bemClass, _computedOption, _layoutOptions, _layouts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Button Group
   * @description This component should wrap a group of `button-async`s. By default, the `button-group`
   * is a block level element, so it will take up 100% of its container's width. `button-async`s in the 
   * group can be aligned to the left, center or right of the group, and the children may be set to take 
   * up their default space, or fill the available space.
    ```handlebars
    {{#button-group align='right' children='flex-grow'}}
      {{button-async
        action=(perform fancyTask)
        default=(t 'some.translated.string')}}
      {{button-async
        task=fancyTask
        default=(t 'some.translated.string')}}
    {{/button-group}}
    ```
   */
  var _default = Ember.Component.extend({
    layout: _buttonGroup.default,
    classNames: ['btn-group'],
    classNameBindings: ['alignCls', 'childrenCls'],

    /**
     * @argument children
     * @description `inline || flex-grow`. `flex-grow` will turn every btn child into `flex: 1`.
     * Optional. Defaults to `inline`.
     * @type {string}
     */
    children: (0, _computedOption.default)('inline', _layoutOptions.BUTTON_GROUP_CHILDREN),
    childrenCls: (0, _bemClass.default)('btn-group', 'modifier', 'children'),

    /**
     * @argument align
     * @description `left || center || right`. Optional. Defaults to `left`.
     * @type {string}
     */
    align: (0, _layouts.align)('left'),
    alignCls: Ember.computed('align', function () {
      var align = Ember.get(this, 'align');
      var alignCls;

      switch (align) {
        case 'left':
          alignCls = 'justify-start';
          break;

        case 'center':
          alignCls = 'justify-center';
          break;

        case 'right':
          alignCls = 'justify-end';
          break;

        default:
          alignCls = 'justify-start';
      }

      return alignCls;
    })
  });

  _exports.default = _default;
});