define("@loom/ember-chrome/components/app-header", ["exports", "@loom/ember-chrome/templates/components/app-header", "@loom/ember-styles/macros/bem-class", "@loom/ember-styles/macros/palettes"], function (_exports, _appHeader, _bemClass, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class App Header
   * @description An optional high level component to be rendered inside of `{{appChrome}}`. 
   * All attributes are available to any component rendered inside of it through the yielded `appChrome` object. 
   * `{{appHeader}}` should be rendered inside of `{{appBody}}`. Content may be rendered inside of `{{appHeader}}` 
   * into a contextual `{{appHeader.section}}`. You may render up to, but no more than 1 section of each alignment: 
   * ['left', 'center', 'right']. `{{appHeader}}` has a CSS position of `fixed` so it will stay fixed to the top of 
   * the viewport when scrolled.
    ```handlebars
    {{#app-chrome as |appChrome|}}
      {{#appChrome.appBody}}
        {{#appChrome.appHeader as |appHeader|}}
          {{#appHeader.section align='left'}}
            <button class='btn btn--regular btn--transparent' {{action appChrome.openSideBar}}>
              {{inline-svg 'menu' class='icon icon--menu'}}
            </button>
            {{appChrome.displayName}}
          {{/appHeader.section}}
  
          {{#appHeader.section align='right'}}
            {{button-async
              default=(t 'app.buttons.sign-out')
              action=(route-action 'signOut')
              palette='white'}}
          {{/appHeader.section}}
        {{/appChrome.appHeader}}
      {{/appChrome.appBody}}
    {{/app-chrome}}
    ```
   */
  var _default = Ember.Component.extend({
    layout: _appHeader.default,
    tagName: 'header',
    classNames: ['app-header'],
    classNameBindings: ['paletteCls', 'sideBarCls'],

    /**
     * @private
     */
    hasLeftSection: false,

    /**
     * @private
     */
    hasCenterSection: false,

    /**
     * @private
     */
    hasRightSection: false,

    /**
     * @argument sideBarType
     * @description `off-canvas || fixed || collapseable`. Sets the sidebar type. Optional. Defaults to `null`.
     * @type {string}
     */
    sideBarType: null,
    sideBarCls: (0, _bemClass.default)('app-sidebar', 'modifier', 'sideBarType'),

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the body of chrome. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the body of chrome. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark'),
    paletteCls: (0, _palettes.paletteCls)(),

    /**
     * @private
     */
    onContentAdded: function onContentAdded(align) {
      if (align === 'left') {
        (false && !(!this.hasLeftSection) && Ember.assert('Header can only have one left section', !this.hasLeftSection));
        this.hasLeftSection = true;
      } else if (align === 'center') {
        (false && !(!this.hasCenterSection) && Ember.assert('Header can only have one center section', !this.hasCenterSection));
        this.hasCenterSection = true;
      } else if (align === 'right') {
        (false && !(!this.hasRightSection) && Ember.assert('Header can only have one right section', !this.hasRightSection));
        this.hasRightSection = true;
      } else {
        (false && !(false) && Ember.assert("unknown header section alignment \"".concat(align, "\"")));
      }
    },

    /**
     * @private
     */
    onContentRemoved: function onContentRemoved(align) {
      if (align === 'left') {
        this.hasLeftSection = false;
      } else if (align === 'center') {
        this.hasCenterSection = false;
      } else if (align === 'right') {
        this.hasRightSection = false;
      } else {
        (false && !(false) && Ember.assert("unknown header section alignment \"".concat(align, "\"")));
      }
    }
  });

  _exports.default = _default;
});