define("ember-svg-jar/inlined/reduce-downtime-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#3D00EC\" fill-opacity=\".25\" d=\"M0 0h56v56H0z\"/><path d=\"M18 12l.016 9.6L24.4 28l-6.384 6.416L18 44h19.2v-9.6L30.8 28l6.4-6.384V12H18zm16 23.2v5.6H21.2v-5.6l6.4-6.4 6.4 6.4z\" fill=\"#3D00EC\"/>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});