define("@loom/ember-styles/services/colors", ["exports", "@loom/ember-styles/utils/theme-options"], function (_exports, _themeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * @private
     */
    palettes: Ember.computed(function () {
      var palettes = Object.create(null);
      var el = document.createElement('div'); // Take the colors and add them into the DOM to grab their computed styles

      document.body.appendChild(el);

      _themeOptions.ALL_PALETTES.forEach(function (palette) {
        var paletteColor = Object.create(null);
        palettes[palette] = paletteColor;
        paletteColor.bg = Object.create(null);
        paletteColor.color = Object.create(null);

        _themeOptions.ALL_SHADES.forEach(function (shade) {
          el.setAttribute('class', "bg__".concat(palette, "--").concat(shade));
          var style = window.getComputedStyle(el);
          paletteColor.bg[shade] = style.backgroundColor;
          paletteColor.color[shade] = style.color;
        });
      });

      document.body.removeChild(el);
      return palettes;
    }),

    /**
     * @public
     * @description Returns a hex value of one of our theme colors.
     * @param {string} palette - Color palette
     * @param {string} shade - Defaults to 'base'
     * @returns {string} hex - hex representation of provided palette/shade
     */
    getColor: function getColor(palette) {
      var shade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'base';
      var color = Ember.get(this, "palettes.".concat(palette, ".bg.").concat(shade));
      (false && !(color) && Ember.assert("no bg color for ".concat(palette, ".").concat(shade), color));
      return color;
    },

    /**
     * @public
     * @description Returns a hex value of the appropriate Text color, to overlay on top of one of our theme colors.
     * @param {string} palette - Color palette
     * @param {string} shade - Defaults to 'base'
     * @returns {string} hex - hex representation of text color for palette/shade
     */
    getTextColor: function getTextColor(palette) {
      var shade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'base';
      var color = Ember.get(this, "palettes.".concat(palette, ".color.").concat(shade));
      (false && !(color) && Ember.assert("no text color for ".concat(palette, ".").concat(shade), color));
      return color;
    }
  });

  _exports.default = _default;
});