define("@loom/ember-text/components/body-text", ["exports", "@loom/ember-text/templates/components/body-text", "@loom/ember-styles/macros/bem-class", "@loom/ember-macros/macros/computed-option", "@loom/ember-styles/macros/palettes", "@loom/ember-styles/utils/typography-options"], function (_exports, _bodyText, _bemClass, _computedOption, _palettes, _typographyOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Body Text
   * @description The body text component is used to display paragraphs of text.
    ```handlebars
    {{body-text
      text=text
      size='body'
      lineHeight='single'
      weight='regular'}}
    ```
   */
  var _default = Ember.Component.extend({
    layout: _bodyText.default,
    'data-test-body-text': true,
    tagName: 'p',
    classNames: ['body-text'],
    classNameBindings: ['lineHeightCls', 'textSizeCls', 'paletteCls', 'weightCls'],

    /**
     * @argument text
     * @description i18n string for the body text. Optional. Defaults to `null`.
     * @type {string}
     */
    text: null,

    /**
     * @argument lineHeight
     * @description `single || double`. Line height for the title. Optional. Defaults to `single`.
     * @type {string}
     */
    lineHeight: (0, _computedOption.default)('single', _typographyOptions.LINE_HEIGHTS),

    /**
     * @argument size
     * @description `footnote || body || caption-1 || caption-2 || callout`.
     * Size for body text. Optional. Defaults to `body`.
     * @type {string}
     */
    size: (0, _computedOption.default)('body', _typographyOptions.BODY_SIZES),

    /**
     * @argument palette
     * @description `black || white`. Palette for body text. Optional. Defaults to `black`.
     * @type {string}
     */
    palette: (0, _palettes.contentPalette)('black'),

    /**
     * @argument paletteModifier
     * @description `low || medium || high || full`. `full` is only available for the `white` palette.
     * The opacity level for the body color. Optional. Defaults to `high`.
     * @type {string}
     */
    paletteModifier: (0, _palettes.contentPaletteModifier)('high'),
    paletteCls: (0, _palettes.contentCls)('palette', 'paletteModifier'),

    /**
     * @argument weight
     * @description `light || book || regular || semibold || bold || black`.
     * Font weight for the body text. Optional. Defaults to `regular`.
     * @type {string}
     */
    weight: (0, _computedOption.default)('regular', _typographyOptions.TYPE_WEIGHTS),
    lineHeightCls: (0, _bemClass.default)('body-line-height', 'modifier', 'lineHeight'),
    textSizeCls: (0, _bemClass.default)('text-size', 'modifier', 'size'),
    weightCls: (0, _bemClass.default)('font-weight', 'modifier', 'weight')
  });

  _exports.default = _default;
});