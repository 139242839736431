define("@loom/ember-text/components/title-text", ["exports", "@loom/ember-text/templates/components/title-text", "@loom/ember-macros/macros/computed-option", "@loom/ember-styles/macros/palettes", "@loom/ember-styles/utils/typography-options"], function (_exports, _titleText, _computedOption, _palettes, _typographyOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Title Text
   * @description The title text component is used to display titles.
   * It's also the same component inside the text-block component, but without the option to pass in body text.
    ```handlebars
    {{title-text
      palette='Primary'
      text='Title Text'
      size='headline'
      weight='regular'
      lineHeight='single'}}
    ```
   */
  var _default = Ember.Component.extend({
    layout: _titleText.default,
    tagName: '',

    /**
     * @argument text
     * @description i18n string for the title text. Optional.
     * @type {string}
     */
    text: null,

    /**
     * @argument lineHeight
     * @description `single || double`. Line height for the title. Optional. Defaults to `single`.
     * @type {string}
     */
    lineHeight: (0, _computedOption.default)('single', _typographyOptions.LINE_HEIGHTS),

    /**
     * @argument size
     * @description `title-1 || title-2 || title-3 || title-4 || title-5 || headline || subheadline`.
     * Size for title text. Optional. Defaults to `title-3`.
     * @type {string}
     */
    size: (0, _computedOption.default)('title-3', _typographyOptions.TITLE_SIZES),

    /**
     * @argument palette
     * @description `black || white`. Palette for title text. Optional. Defaults to `black`.
     * @type {string}
     */
    palette: (0, _palettes.contentPalette)('black'),

    /**
     * @argument paletteModifier
     * @description `low || medium || high || full`. `full` is only available for the `white` palette.
     * The opacity level for the title color. Optional. Defaults to `high`.
     * @type {string}
     */
    paletteModifier: (0, _palettes.contentPaletteModifier)('high'),
    paletteCls: (0, _palettes.contentCls)('palette', 'paletteModifier'),

    /**
     * @argument weight
     * @description `light || book || regular || semibold || bold || black`.
     * Font weight for the title text. Optional. Defaults to `semibold`.
     * @type {string}
     */
    weight: (0, _computedOption.default)('regular', _typographyOptions.TYPE_WEIGHTS),
    classes: Ember.computed('class', 'lineHeight', 'size', 'weight', 'paletteCls', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'class', 'lineHeight', 'size', 'weight', 'paletteCls'),
          cls = _EmberGetProperties.class,
          lineHeight = _EmberGetProperties.lineHeight,
          size = _EmberGetProperties.size,
          weight = _EmberGetProperties.weight,
          paletteCls = _EmberGetProperties.paletteCls;

      var classes = (cls || '').split(' ');
      var fontWeight = size === 'title-5' ? 'font-weight--bold' : "font-weight--".concat(weight);
      classes.push('title-text', "text-line-height--".concat(lineHeight), "text-size--".concat(size), fontWeight, paletteCls);

      if (size !== 'subheadline') {
        classes.push('letter-spacing');
      }

      return classes.join(' ');
    })
  });

  _exports.default = _default;
});