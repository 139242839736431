define("@loom/ember-buttons/templates/components/button-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L4RRa2tx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"state\",\"url\"],[[24,[\"_btnState\"]],[24,[\"_href\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"_assertProps\"],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"iconOnly\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"btn__icon\"],[8],[1,[28,\"component\",[[24,[\"_icon\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"iconBefore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"btn__icon btn__icon--before\"],[8],[1,[28,\"component\",[[24,[\"_icon\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[22,\"_text\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"iconAfter\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"btn__icon btn__icon--after\"],[8],[1,[28,\"component\",[[24,[\"_icon\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-buttons/templates/components/button-basic.hbs"
    }
  });

  _exports.default = _default;
});