define("ember-svg-jar/inlined/cornering-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.083 16.328l.89.89a1.193 1.193 0 11-1.68 1.694l-2.821-2.827a1.193 1.193 0 01-.472-.949c0-.372.173-.724.423-.91l2.865-2.864a1.193 1.193 0 111.692 1.682l-.897.898h8.856c3.89 0 7.061 3.17 7.061 7.071-.099 4.396-3.061 7.051-7.061 7.051H20.01a1.193 1.193 0 11-.006-2.386h2.934a4.664 4.664 0 004.675-4.675 4.664 4.664 0 00-4.675-4.675h-8.856zm-2.88 9.35a1.193 1.193 0 110 2.386 1.193 1.193 0 010-2.386zm2.934 0a1.193 1.193 0 110 2.386 1.193 1.193 0 010-2.386zm2.934 0a1.193 1.193 0 110 2.386 1.193 1.193 0 010-2.386z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});