define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 10.286h-7.714V18H7.714v-7.714H0V7.714h7.714V0h2.572v7.714H18v2.572z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none"
    }
  };
  _exports.default = _default;
});