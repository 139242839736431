define("@loom/ember-chrome/templates/components/app-chrome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QeYkDyrU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"sideBar\",\"appBody\",\"appHeader\",\"openSideBar\",\"closeSideBar\",\"toggleCollapse\",\"displayName\",\"sideBarIsCollapsed\"],[[28,\"component\",[\"app-sidebar\"],[[\"appChrome\",\"palette\",\"type\",\"isOpen\",\"isCollapsed\"],[[23,0,[]],[24,[\"palette\"]],\"off-canvas\",[24,[\"sideBarIsOpen\"]],[24,[\"sideBarIsCollapsed\"]]]]],[28,\"component\",[\"app-body\"],[[\"sideBarType\",\"palette\"],[[23,0,[\"sideBarType\"]],[24,[\"palette\"]]]]],[28,\"component\",[\"app-header\"],[[\"sideBarType\",\"palette\"],[[23,0,[\"sideBarType\"]],[24,[\"palette\"]]]]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sideBarIsOpen\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sideBarIsOpen\"]]],null],false],null],[28,\"action\",[[23,0,[]],[24,[\"toggleProperty\"]],\"sideBarIsCollapsed\"],null],[24,[\"displayName\"]],[24,[\"sideBarIsCollapsed\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-chrome/templates/components/app-chrome.hbs"
    }
  });

  _exports.default = _default;
});