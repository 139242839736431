define("@loom/ember-text/components/text-block", ["exports", "@loom/ember-text/templates/components/text-block", "@loom/ember-styles/macros/bem-class", "@loom/ember-macros/macros/computed-option", "@loom/ember-styles/macros/palettes", "@loom/ember-styles/macros/layouts", "@loom/ember-styles/utils/typography-options"], function (_exports, _textBlock, _bemClass, _computedOption, _palettes, _layouts, _typographyOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Text Block
   * @description The text block component is a wrapper around a conditional `{{title-text}}` and conditional
   * `{{body-text`}}. This component also provides display and layout properties for the whole block.
    ```handlebars
    {{text-block
      titleText=(t text)
      titleSize='headline'
      titleWeight='regular'
      bodyText=text
      bodySize='body'
      bodyWeight='regular'
      align='center'
      padding='small'}}
    ```
   */
  var _default = Ember.Component.extend({
    classNames: ['text-block'],
    classNameBindings: ['alignCls', 'lineHeightCls', 'paddingCls'],
    layout: _textBlock.default,
    'data-test-text-block': true,
    // General Config

    /**
     * @argument align
     * @description `left || center || right `. Alignment for all text in the block. Optional. Defaults to `left`.
     * @type {string}
     */
    align: (0, _layouts.align)('left'),

    /**
     * @argument lineHeight
     * @description `single || double`. Line height for all text in the block. Optional. Defaults to `single`.
     * @type {string}
     */
    lineHeight: (0, _computedOption.default)('single', _typographyOptions.LINE_HEIGHTS),

    /**
     * @argument padding
     * @description `none || x-small || small || default || large || x-large`.
     * Top and bottom padding for the text block. Optional. Defaults to `default` which is `1.5em`.
     * @type {string}
     */
    padding: (0, _layouts.size)('default'),
    alignCls: (0, _layouts.alignCls)(),
    lineHeightCls: (0, _bemClass.default)('text-line-height', 'modifier', 'lineHeight'),
    // Title Config

    /**
     * @argument titleText
     * @description i18n string for the title text. Optional. Defaults to `null`.
     * @type {string}
     */
    titleText: null,

    /**
     * @argument titleSize
     * @description `title-1 || title-2 || title-3 || title-4 || title-5 || headline || subheadline`.
     * Size for title text. Optional. Defaults to `title-3`.
     * @type {string}
     */
    titleSize: (0, _computedOption.default)('title-3', _typographyOptions.TITLE_SIZES),

    /**
     * @argument titleWeight
     * @description `light || book || regular || semibold || bold || black`.
     * Font weight for the title text. Optional. Defaults to `semibold`.
     * @type {string}
     */
    titleWeight: (0, _computedOption.default)('semibold', _typographyOptions.TYPE_WEIGHTS),

    /**
     * @argument titlePalette
     * @description `black || white`. Palette for title text. Optional. Defaults to `black`.
     * @type {string}
     */
    titlePalette: (0, _palettes.contentPalette)('black'),

    /**
     * @argument titlePaletteModifier
     * @description `low || medium || high || full`. `full` is only available for the `white` palette.
     * The opacity level for the title color. Optional. Defaults to `high`.
     * @type {string}
     */
    titlePaletteModifier: (0, _palettes.contentPaletteModifier)('high'),
    // Subtitle Config

    /**
     * @argument subtitleText
     * @description i18n string for the subtitle text. Optional. Defaults to `null`.
     * @type {string}
     */
    subtitleText: null,

    /**
     * @argument subtitleSize
     * @description `title-1 || title-2 || title-3 || title-4 || title-5 || headline || subheadline`.
     * Size for subtitle text. Optional. Defaults to `subheadline`.
     * @type {string}
     */
    subtitleSize: (0, _computedOption.default)('subheadline', _typographyOptions.SUB_TITLE_SIZES),

    /**
     * @argument subtitleWeight
     * @description `light || book || regular || semibold || bold || black`.
     * Font weight for the subtitle text. Optional. Defaults to `semibold`.
     * @type {string}
     */
    subtitleWeight: (0, _computedOption.default)('semibold', _typographyOptions.TYPE_WEIGHTS),

    /**
     * @argument subtitlePalette
     * @description `black || white`. Palette for subtitle text. Optional. Defaults to `black`.
     * @type {string}
     */
    subtitlePalette: (0, _palettes.contentPalette)('black'),

    /**
     * @argument subtitlePaletteModifier
     * @description `low || medium || high || full`. `full` is only available for the `white` palette.
     * The opacity level for the subtitle color. Optional. Defaults to `high`.
     * @type {string}
     */
    subtitlePaletteModifier: (0, _palettes.contentPaletteModifier)('high'),
    // Body Config

    /**
     * @argument bodyText
     * @description i18n string for the smaller text. Optional.
     * @type {string}
     */
    bodyText: null,

    /**
     * @argument bodySize
     * @description `footnote || body || caption-1 || caption-2 || callout`.
     * Size for body text. Optional. Defaults to `body`.
     * @type {string}
     */
    bodySize: (0, _computedOption.default)('body', _typographyOptions.BODY_SIZES),

    /**
     * @argument bodyWeight
     * @description `light || book || regular || semibold || bold || black`.
     * Font weight for the body text. Optional. Defaults to `regular`.
     * @type {string}
     */
    bodyWeight: (0, _computedOption.default)('regular', _typographyOptions.TYPE_WEIGHTS),

    /**
     * @argument bodyPalette
     * @description `black || white`. Palette for body text. Optional. Defaults to `black`.
     * @type {string}
     */
    bodyPalette: (0, _palettes.contentPalette)('black'),

    /**
     * @argument bodyPaletteModifier
     * @description `low || medium || high || full`. `full` is only available for the `white` palette.
     * The opacity level for the body color. Optional. Defaults to `high`.
     * @type {string}
     */
    bodyPaletteModifier: (0, _palettes.contentPaletteModifier)('high'),
    // Caption Config

    /**
     * @argument captionText
     * @description i18n string for the caption-sized text. Optional.
     * @type {string}
     */
    captionText: null,

    /**
     * @argument captionSize
     * @description `caption-1 || caption-2`.
     * Size for caption text. Optional. Defaults to `caption-1`.
     * @type {string}
     */
    captionSize: (0, _computedOption.default)('caption-1', _typographyOptions.CAPTION_SIZES),

    /**
     * @argument captionWeight
     * @description `light || book || regular || semibold || bold || black`.
     * Font weight for the caption text. Optional. Defaults to `regular`.
     * @type {string}
     */
    captionWeight: (0, _computedOption.default)('regular', _typographyOptions.TYPE_WEIGHTS),

    /**
     * @argument captionPalette
     * @description `black || white`. Palette for caption text. Optional. Defaults to `black`.
     * @type {string}
     */
    captionPalette: (0, _palettes.contentPalette)('black'),

    /**
     * @argument captionPaletteModifier
     * @description `low || medium || high || full`. `full` is only available for the `white` palette.
     * The opacity level for the body color. Optional. Defaults to `high`.
     * @type {string}
     */
    captionPaletteModifier: (0, _palettes.contentPaletteModifier)('high'),
    // Padding Class
    paddingCls: (0, _layouts.paddingCls)('padding')
  });

  _exports.default = _default;
});