define("@loom/ember-dropdown/components/dropdown-menu", ["exports", "ember-basic-dropdown/components/basic-dropdown", "@loom/ember-dropdown/templates/components/dropdown-menu", "@loom/ember-styles/macros/palettes"], function (_exports, _basicDropdown, _dropdownMenu, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dropdown Menu
   * @description A customizable dropdown menu extended from 
   * [ember-basic-dropdown](https://github.com/cibernox/ember-basic-dropdown).
    ```handlebars
    {{#dropdown-menu horizontalPosition='center' as |dropdown|}}
      {{dropdown.trigger text=(t 'groups')}}
      {{#dropdown.content as |content|}}
        {{#content.item}}{{#link-to 'geo.vehicles'}}{{t 'geo.vehicles'}}{{/link-to}}{{/content.item}}
        {{#content.item}}{{#link-to 'geo.stations'}}{{t 'geo.stations'}}{{/link-to}}{{/content.item}}
      {{/dropdown.content}}
    {{/dropdown-menu}}```
   */
  var _default = _basicDropdown.default.extend({
    layout: _dropdownMenu.default,
    classNames: ['dropdown-menu'],
    triggerComponent: 'dropdown-trigger',
    contentComponent: 'dropdown-content',

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the dropdown menu. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color for the dropdown
     * menu. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark')
  });

  _exports.default = _default;
});