define("ember-svg-jar/inlined/tc-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M.265.188v20.247h29.021v49.05h20.251v-49.05H39.411V.188H.265M69.853 10.31h10.124V.188H69.853z\"/><path d=\"M39.411 10.31h10.123V.188H39.411zM49.536 20.435h10.123V10.313H49.536zM39.409 20.434h5.062v-5.062h-5.062zM55.054 5.25h5.061V.188h-5.061zM64.79 15.375h5.064v-5.062H64.79z\"/></g>",
    "attrs": {
      "viewBox": "0 0 80 70"
    }
  };
  _exports.default = _default;
});