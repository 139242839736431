define("ember-svg-jar/inlined/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 11.25l-1.19-1.19-4.716 4.708V0H6.406v14.768L1.69 10.06.5 11.25 7.25 18 14 11.25z\"/>",
    "attrs": {
      "width": "14",
      "height": "18",
      "viewBox": "0 0 14 18"
    }
  };
  _exports.default = _default;
});