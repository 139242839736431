define("@loom/ember-styles/utils/typography-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LINE_HEIGHTS = _exports.TYPE_SHADES = _exports.TYPE_WEIGHTS = _exports.CAPTION_SIZES = _exports.BODY_SIZES = _exports.SUB_TITLE_SIZES = _exports.TITLE_SIZES = void 0;
  var TITLE_SIZES = ['title-1', 'title-2', 'title-3', 'title-4', 'title-5', 'subheadline', 'headline'];
  _exports.TITLE_SIZES = TITLE_SIZES;
  var SUB_TITLE_SIZES = ['subheadline', 'headline'];
  _exports.SUB_TITLE_SIZES = SUB_TITLE_SIZES;
  var BODY_SIZES = ['footnote', 'body', 'caption-1', 'caption-2', 'callout'];
  _exports.BODY_SIZES = BODY_SIZES;
  var CAPTION_SIZES = ['caption-1', 'caption-2', 'footnote'];
  _exports.CAPTION_SIZES = CAPTION_SIZES;
  var TYPE_WEIGHTS = ['light', 'book', 'regular', 'semibold', 'bold', 'black'];
  _exports.TYPE_WEIGHTS = TYPE_WEIGHTS;
  var TYPE_SHADES = ['full', 'high', 'medium', 'low'];
  _exports.TYPE_SHADES = TYPE_SHADES;
  var LINE_HEIGHTS = ['single', 'double'];
  _exports.LINE_HEIGHTS = LINE_HEIGHTS;
});