define("ember-svg-jar/inlined/fuel-level-alerts-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.987 17.525l.008-.008-3.063-3.1-.873.883 1.738 1.758a2.074 2.074 0 00-1.302 2.259 2.1 2.1 0 001.812 1.758c.387.042.725-.025 1.046-.167v6.842h-1.647V21.5h-2.47V14H11v15h8.235v-6.25h1.236v6.242h4.117V19c0-.575-.23-1.1-.6-1.475zm-6.399 2.308h-4.94v-4.166h4.94v4.166zM21.706 19c0 .458.37.833.823.833a.83.83 0 00.824-.833.83.83 0 00-.824-.833.83.83 0 00-.823.833z\" fill=\"#999A9C\"/><mask id=\"mask0\" maskUnits=\"userSpaceOnUse\" x=\"11\" y=\"14\" width=\"14\" height=\"15\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.987 17.525l.008-.008-3.063-3.1-.873.883 1.738 1.758a2.074 2.074 0 00-1.302 2.259 2.1 2.1 0 001.812 1.758c.387.042.725-.025 1.046-.167v6.842h-1.647V21.5h-2.47V14H11v15h8.235v-6.25h1.236v6.242h4.117V19c0-.575-.23-1.1-.6-1.475zm-6.399 2.308h-4.94v-4.166h4.94v4.166zM21.706 19c0 .458.37.833.823.833a.83.83 0 00.824-.833.83.83 0 00-.824-.833.83.83 0 00-.823.833z\" fill=\"#fff\"/></mask><g mask=\"url(#mask0)\"><path fill=\"#0C0C0C\" d=\"M7.706 25.666h19.765v6.667H7.706z\"/></g><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.5 21a6.5 6.5 0 100-13 6.5 6.5 0 000 13z\" fill=\"#E8E8E8\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.5 18a3.5 3.5 0 100-7 3.5 3.5 0 000 7z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.5 9.916a4.585 4.585 0 00-4.583 4.584 4.585 4.585 0 004.583 4.583 4.585 4.585 0 004.583-4.583A4.585 4.585 0 0026.5 9.916zm-.458 6.875v-.916h.916v.916h-.916zm0-4.583v2.75h.916v-2.75h-.916z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});