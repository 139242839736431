define("@loom/ember-dropdown/templates/components/dropdown-content-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x9ZnYo0g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-dropdown/templates/components/dropdown-content-item.hbs"
    }
  });

  _exports.default = _default;
});