define("ember-svg-jar/inlined/right-arrow-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.875.167l-.991.991 3.923 3.93H.5v1.407h12.307l-3.923 3.93.991.992L15.5 5.792z\"/>",
    "attrs": {
      "width": "16",
      "height": "12",
      "viewBox": "0 0 16 12"
    }
  };
  _exports.default = _default;
});