define("@loom/ember-styles/macros/palettes", ["exports", "@loom/ember-macros/macros/computed-option", "@loom/ember-styles/utils/theme-options"], function (_exports, _computedOption, _themeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.palette = palette;
  _exports.paletteShade = paletteShade;
  _exports.paletteCls = paletteCls;
  _exports.contentPalette = contentPalette;
  _exports.contentPaletteModifier = contentPaletteModifier;
  _exports.contentCls = contentCls;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function palette() {
    var def = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'primary';
    var allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _themeOptions.ALL_PALETTES;
    return (0, _computedOption.default)(def, allowed);
  }

  function paletteShade() {
    var def = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'base';
    var allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _themeOptions.ALL_SHADES;
    return (0, _computedOption.default)(def, allowed);
  }

  function paletteCls() {
    var paletteProp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'palette';
    var shadeProp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'shade';
    return Ember.computed(paletteProp, shadeProp, {
      get: function get() {
        var paletteVal = Ember.get(this, paletteProp);
        (false && !(Boolean(paletteVal)) && Ember.assert("palette is required, was \"".concat(paletteVal, "\""), Boolean(paletteVal)));
        var shade = Ember.getWithDefault(this, shadeProp, 'base');
        (false && !(Boolean(shade)) && Ember.assert("shade is required, was \"".concat(shade, "\""), Boolean(shade)));
        return "bg__".concat(paletteVal, "--").concat(shade);
      }
    });
  }

  function contentPalette() {
    var def = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'white';
    var allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _themeOptions.ALL_CONTENT_PALETTES;
    return (0, _computedOption.default)(def, allowed);
  }

  function contentPaletteModifier() {
    var def = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'full';
    var allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _themeOptions.ALL_CONTENT_MODIFIERS;
    return (0, _computedOption.default)(def, allowed);
  }

  function contentCls() {
    var paletteProp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'palette';
    var paletteModifierProp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'paletteModifier';

    var check = function check() {};

    Ember.runInDebug(function () {
      check = function check(paletteVal, paletteModifier) {
        (false && !(_themeOptions.ALL_CONTENT_PALETTES.has(paletteVal)) && Ember.assert("palette must be one of {".concat(_toConsumableArray(_themeOptions.ALL_CONTENT_PALETTES).join(','), "}, was ").concat(paletteVal), _themeOptions.ALL_CONTENT_PALETTES.has(paletteVal)));

        if (_themeOptions.ALL_PALETTES.has(paletteVal)) {
          (false && !(_themeOptions.ALL_SHADES.has(paletteModifier)) && Ember.assert("shade must be one of {".concat(_toConsumableArray(_themeOptions.ALL_SHADES).join(','), "}, was ").concat(paletteModifier), _themeOptions.ALL_SHADES.has(paletteModifier)));
        } else {
          (false && !(_themeOptions.CONTENT_ONLY_OPACITIES.has(paletteModifier)) && Ember.assert("opacity must be one of {".concat(_toConsumableArray(_themeOptions.CONTENT_ONLY_OPACITIES).join(','), "}, was ").concat(paletteModifier), _themeOptions.CONTENT_ONLY_OPACITIES.has(paletteModifier)));
          (false && !(paletteVal === 'white' || paletteModifier !== 'full') && Ember.assert('black palette may not have full opacity', paletteVal === 'white' || paletteModifier !== 'full'));
        }
      };
    });
    return Ember.computed(paletteProp, paletteModifierProp, {
      get: function get() {
        var paletteVal = Ember.get(this, paletteProp);
        var whitePalette = paletteVal === 'white' ? 'full' : 'base';
        var defaultPalette = paletteVal === 'black' ? 'high' : whitePalette;
        var paletteModifier = Ember.getWithDefault(this, paletteModifierProp, defaultPalette);
        check(paletteVal, paletteModifier);
        return "color__".concat(paletteVal, "--").concat(paletteModifier);
      }
    });
  }
});