define("ember-svg-jar/inlined/alert-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm.9 13.5H8.1v-1.8h1.8v1.8zm0-3.6H8.1V4.5h1.8v5.4z\" fill=\"#EB0A1E\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none"
    }
  };
  _exports.default = _default;
});