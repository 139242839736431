define("@loom/ember-text/templates/components/title-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ChSjOz3K",
    "block": "{\"symbols\":[\"@text\",\"&default\",\"&attrs\"],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"eq\",[[24,[\"size\"]],\"title-4\"],null],[28,\"eq\",[[24,[\"size\"]],\"title-5\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"h1\",false],[12,\"class\",[23,0,[\"classes\"]]],[12,\"data-test-title-text\",\"\"],[13,3],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"size\"]],\"title-3\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",false],[12,\"class\",[23,0,[\"classes\"]]],[12,\"data-test-title-text\",\"\"],[13,3],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"size\"]],\"title-1\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"h4\",false],[12,\"class\",[23,0,[\"classes\"]]],[12,\"data-test-title-text\",\"\"],[13,3],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h2\",false],[12,\"class\",[23,0,[\"classes\"]]],[12,\"data-test-title-text\",\"\"],[13,3],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-text/templates/components/title-text.hbs"
    }
  });

  _exports.default = _default;
});