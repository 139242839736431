define("@loom/ember-chrome/components/app-sidebar-footer", ["exports", "@loom/ember-chrome/templates/components/app-sidebar-footer", "@loom/ember-chrome/components/app-sidebar", "@loom/ember-styles/macros/palettes"], function (_exports, _appSidebarFooter, _appSidebar, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class App Sidebar Footer
   * @description A required component to be rendered inside of `{{appSidebar}}`.
   * See `{{appSidebar}}` for more information.
   */
  var _default = Ember.Component.extend({
    layout: _appSidebarFooter.default,
    classNames: ['app-sidebar__footer'],
    classNameBindings: ['paletteCls'],

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the body of chrome. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the body of chrome. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark'),
    paletteCls: (0, _palettes.paletteCls)(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (false && !(this.sideBar instanceof _appSidebar.default) && Ember.assert('app-sidebar-footer must be rendered inside of an app-sidebar', this.sideBar instanceof _appSidebar.default));
      this.sideBar.onContentAdded('footer');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.sideBar) {
        this.sideBar.onContentRemoved('footer');
      }
    }
  });

  _exports.default = _default;
});