define("ember-svg-jar/inlined/build-data-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path d=\"M8.666 10.667v18.667h22.667V10.667H8.666zm5.333 16h-2.666V24h2.666v2.667zm0-5.333h-2.666v-2.667h2.666v2.667zM14 16h-2.666v-2.666h2.666V16zm14.667 10.667h-12V24h12v2.667zm0-5.333h-12v-2.667h12v2.667zm0-5.334h-12v-2.666h12V16z\" fill=\"#000\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});