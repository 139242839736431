define("@loom/ember-styles/utils/pull-content-from-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pullPalettes = pullPalettes;

  /**
   * @description Appends a DIV to pull our palettes from the SCSS files
   * @param {string[]} fallbacks - Palette fallbacks in case errors occur
   * @returns {string[]} palettes
   */
  function pullPalettes(fallbacks) {
    var paletteDiv;

    try {
      paletteDiv = document.createElement('div');
      paletteDiv.setAttribute('data-loom-tooling', 'palettes');
      document.body.appendChild(paletteDiv);
      var stashedContent = window.getComputedStyle(paletteDiv, '::after').getPropertyValue('content');

      if (stashedContent === 'none') {
        // The loom CSS is not available. Fall back to default loom palettes
        return fallbacks;
      }

      return stashedContent.split(',').map(function (palette) {
        return palette.trim().replace('"', '');
      });
    } catch (err) {
      return fallbacks;
    } finally {
      document.body.removeChild(paletteDiv);
    }
  }
});