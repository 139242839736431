define("ember-svg-jar/inlined/collision-severity-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path d=\"M26.92 14.01c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01L11 20v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM14.5 24c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM13 19l1.5-4.5h11L27 19H13z\" fill=\"#000\"/><circle cx=\"28.5\" cy=\"12.5\" r=\"6.5\" fill=\"#E8E8E8\"/><path d=\"M28.5 8.167a4.335 4.335 0 00-4.334 4.333 4.335 4.335 0 004.334 4.334 4.335 4.335 0 004.333-4.334A4.335 4.335 0 0028.5 8.167zm.433 6.5h-.866v-2.6h.866v2.6zm0-3.467h-.866v-.866h.866v.866z\" fill=\"#000\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});