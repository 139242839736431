define("@loom/ember-chrome/components/app-body", ["exports", "@loom/ember-chrome/templates/components/app-body", "@loom/ember-styles/macros/bem-class", "@loom/ember-styles/macros/palettes"], function (_exports, _appBody, _bemClass, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class App Body
   * @description A required high level component to be rendered inside of `{{appChrome}}`. `{{appBody}}` 
   * is presentational, and meant to force the content to take up 100% of the viewport height. All 
   * attributes are available to any component rendered inside of it through the yielded `appChrome` 
   * object. `{{appBody}}` should be rendered as a sibling of `{{appSidebar}}`. It is important to remember 
   * to include the following code in your `{{appBody}}`:
    ```handlebars
    <section class='app-content'>
      {{outlet}}
    </section>
    ```
    ```handlebars
    {{#app-chrome as |appChrome|}}
      {{#appChrome.appBody}}
        <section class='app-content'>
          {{outlet}}
        </section>
      {{/appChrome.appBody}}
    {{/app-chrome}}
    ```
   */
  var _default = Ember.Component.extend({
    layout: _appBody.default,
    tagName: 'main',
    'data-test-app-body': true,
    role: 'main',
    attributeBindings: ['role'],
    classNames: ['app-body'],
    classNameBindings: ['paletteCls', 'sideBarCls'],

    /**
     * @argument sideBarType
     * @description `off-canvas || fixed || collapseable`. Sets the sidebar type. Optional. Defaults to `null`.
     * @type {string}
     */
    sideBarType: null,
    sideBarCls: (0, _bemClass.default)('app-sidebar', 'modifier', 'sideBarType'),

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the body of chrome. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the body of chrome. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark'),
    paletteCls: (0, _palettes.paletteCls)('palette', 'shade')
  });

  _exports.default = _default;
});