define("ember-svg-jar/inlined/speed-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path d=\"M28.39 16.56l-1.24 1.86a8 8 0 01-.22 7.58H13.07a8 8 0 0110.51-11.15l1.86-1.24A10 10 0 0012 28h16a10 10 0 00.38-11.44h.01z\" fill=\"#0C0C0C\"/><path d=\"M18.59 23.41a2 2 0 002.83 0l5.66-8.49-8.49 5.66a2 2 0 000 2.83z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});