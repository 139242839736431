define("ember-svg-jar/inlined/notifications-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.57 31.212a4.677 4.677 0 01-9.164 0zM19.986 4.95a1.87 1.87 0 011.852 2.132 9.353 9.353 0 017.5 9.167v7.482l3.74 3.742h-.002v1.87H6.894v-1.87l3.74-3.742V16.25a9.353 9.353 0 017.5-9.167 1.87 1.87 0 011.852-2.133z\"/>",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
  _exports.default = _default;
});