define("ember-svg-jar/inlined/window-status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28.537 18.264l-6.8-6.8c-.3-.3-.47-.464-.47-.464H11v18h10.39s.047-.113.136-.291a7.117 7.117 0 016.39-3.928H29v-6.03s-.165-.189-.463-.488zm-11.209 5.463c0 1.163-.946 2.109-2.11 2.109a2.111 2.111 0 01-2.109-2.11v-1.054h4.219v1.055zm-3.691-3.2h14.142s-.76-.798-1.043-1.08l-6.182-6.183c-.1-.099-.159-.155-.159-.155H13.11V20.527h.528z\" fill=\"#0C0C0C\"/><path d=\"M22.592 16.818h-8.387l-.04 2.655H25.27l-2.68-2.655z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});