define("ember-svg-jar/inlined/drive-mode-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path d=\"M15.125 10.975c-1.125.475-1.75 1.525-2.65 4.325-.75 2.375-.975 2.85-1.425 2.9-1.075.15-1.3.925-1.3 4.225 0 3.075 0 3.075.6 3.075.525 0 .6.125.7 1.3.1.95.3 1.425.7 1.75 1.35 1.1 3 .05 3-1.925V25.5H25.5v1.225c0 1.05.1 1.325.65 1.75.85.65 1.35.65 2.2 0 .55-.425.65-.7.65-1.75 0-1.2.025-1.225.75-1.225h.75v-2.95c0-3.325-.25-4.1-1.375-4.3-.675-.125-.775-.275-1.625-2.95-.675-2.15-1.075-2.975-1.7-3.625l-.8-.8-4.7-.05c-2.575-.025-4.9.05-5.175.15zm9.35 2.375c.225.275 1.775 4.475 1.775 4.825 0 .05-2.8.075-6.25.075-3.425 0-6.25-.025-6.25-.075.025-.425 1.5-4.5 1.725-4.775.475-.55 8.55-.6 9-.05zM13.8 20.675c.95.85.375 2.325-.925 2.325-1.275 0-1.825-1.325-.95-2.3.5-.55 1.275-.575 1.875-.025zm14.45.075c.6.6.625.95.1 1.7-.725 1.05-2.6.425-2.6-.875 0-.5.95-1.325 1.5-1.325.275 0 .725.225 1 .5z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  };
  _exports.default = _default;
});