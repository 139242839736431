define("ember-svg-jar/inlined/odometer-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10 12v16h20V12H10zm5 4h-2v8h2v-8zm6 8h-5v-8h5v8zm1 0h5v-8h-5v8zm2-6h1v4h-1v-4zm-6 0h1v4h-1v-4zm-6 8h16V14H12v12z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});