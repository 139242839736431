define("ember-svg-jar/inlined/right-corner-arrow-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.641 10.46v1.682l6.664.006L9.863 22.59l1.193 1.193L21.499 13.34l.006 6.664h1.682V10.46h-9.546z\" fill=\"#000\"/>",
    "attrs": {
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});