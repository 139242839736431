define("@loom/ember-styles/utils/theme-options", ["exports", "@loom/ember-styles/utils/pull-content-from-styles"], function (_exports, _pullContentFromStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ALL_CONTENT_MODIFIERS = _exports.ALL_CONTENT_PALETTES = _exports.CONTENT_ONLY_OPACITIES = _exports.CONTENT_ONLY_PALETTES = _exports.ALL_SHADES = _exports.ALL_PALETTES = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var LOOM_PALETTES = ['primary', 'secondary', 'tertiary', 'quaternary', 'negative', 'alert', 'background'];
  var ALL_PALETTES = new Set((0, _pullContentFromStyles.pullPalettes)(LOOM_PALETTES));
  _exports.ALL_PALETTES = ALL_PALETTES;
  var ALL_SHADES = new Set(['base', 'light', 'dark']);
  _exports.ALL_SHADES = ALL_SHADES;
  var CONTENT_ONLY_PALETTES = new Set(['black', 'white']);
  _exports.CONTENT_ONLY_PALETTES = CONTENT_ONLY_PALETTES;
  var CONTENT_ONLY_OPACITIES = new Set(['full', 'high', 'medium', 'low']);
  _exports.CONTENT_ONLY_OPACITIES = CONTENT_ONLY_OPACITIES;
  var ALL_CONTENT_PALETTES = new Set([].concat(_toConsumableArray(CONTENT_ONLY_PALETTES), _toConsumableArray(ALL_PALETTES)));
  _exports.ALL_CONTENT_PALETTES = ALL_CONTENT_PALETTES;
  var ALL_CONTENT_MODIFIERS = new Set([].concat(_toConsumableArray(CONTENT_ONLY_OPACITIES), _toConsumableArray(ALL_SHADES)));
  _exports.ALL_CONTENT_MODIFIERS = ALL_CONTENT_MODIFIERS;
});