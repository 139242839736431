define("ember-svg-jar/inlined/location-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M27 17.15c0-4.69-3.73-7.15-7-7.15s-7 2.46-7 7.15c0 3.12 2.33 6.41 7 9.85 4.67-3.44 7-6.73 7-9.85zM20 15c-1.1 0-2 .9-2 2s.9 2 2 2a2 2 0 100-4zm7 13v2H13v-2h14z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});