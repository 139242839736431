define("@loom/ember-styles/helpers/color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Helper.extend({
    colors: Ember.inject.service(),
    compute: function compute(params, _ref) {
      var _ref$type = _ref.type,
          type = _ref$type === void 0 ? 'bg' : _ref$type;

      if (type === 'bg') {
        var _EmberGet;

        return (_EmberGet = Ember.get(this, 'colors')).getColor.apply(_EmberGet, _toConsumableArray(params));
      }

      if (type === 'text') {
        var _EmberGet2;

        return (_EmberGet2 = Ember.get(this, 'colors')).getTextColor.apply(_EmberGet2, _toConsumableArray(params));
      }

      throw new Error("unknown color type ".concat(type));
    }
  });

  _exports.default = _default;
});