define("@loom/ember-dropdown/components/dropdown-trigger", ["exports", "ember-basic-dropdown/components/basic-dropdown/trigger", "@loom/ember-dropdown/templates/components/dropdown-trigger", "@loom/ember-macros/macros/computed-option", "@loom/ember-styles/macros/palettes"], function (_exports, _trigger, _dropdownTrigger, _computedOption, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dropdown Trigger
   * @description This is a inner component of the Dropdown Menu component. It is a button
   * which triggers the dropdown menu to open.
   */
  var _default = _trigger.default.extend({
    layout: _dropdownTrigger.default,
    classNames: ['dropdown-menu__trigger'],
    classNameBindings: ['paletteCls'],
    tagName: 'button',

    /**
     * @argument triggerIcon
     * @description `disclose || arrow-down`
     * Icon to use for the trigger's expanded/ collapsed indicator. Optional. Defaults to `disclose`.
     * @type {string}
     */
    triggerIcon: (0, _computedOption.default)('disclose', ['disclose', 'arrow-down']),
    _svgComponent: Ember.computed('triggerIcon', function () {
      return "svg-".concat(Ember.get(this, 'triggerIcon'));
    }),

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the dropdown trigger. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the dropdown
     * menu trigger. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark'),
    paletteCls: (0, _palettes.paletteCls)(),

    /**
     * @argument iconCls
     * @description Allows the consumer to provide a class for the icon SVG element.
     * Optional. Defaults to `svg-fill__${palette}--{$shade}-text`.
     * @type {string}
     */
    iconCls: Ember.computed('palette', 'shade', {
      get: function get() {
        return "svg-fill__".concat(Ember.get(this, 'palette'), "--").concat(Ember.get(this, 'shade'), "-text");
      },
      set: function set(k, customVal) {
        return customVal;
      }
    })
  });

  _exports.default = _default;
});