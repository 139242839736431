define("ember-svg-jar/inlined/simplified-fleet-management-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9E00FF\" fill-opacity=\".2\" d=\"M0 0h56v56H0z\"/><path d=\"M44 12H12v28.252h11.577v2.906h8.846v-2.906h11.563L44 12zm-2.822 25.432H14.822V14.82h26.356v22.613z\" fill=\"#9E00FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M32.8 23.333a3.2 3.2 0 11-6.4 0 3.2 3.2 0 016.4 0zM24.266 29.2a2.133 2.133 0 100-4.267 2.133 2.133 0 000 4.267zm5.067 1.6a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667z\" fill=\"#9E00FF\"/>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});