define("ember-svg-jar/inlined/near-real-time-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#3D00EC\" fill-opacity=\".25\" d=\"M0 0h56v56H0z\"/><g clip-path=\"url(#clip0)\"><path d=\"M43.997 24.658H31.944l4.87-5.014c-4.853-4.8-12.71-4.977-17.564-.177a12.222 12.222 0 000 17.404c4.854 4.818 12.711 4.818 17.565 0 2.417-2.382 3.626-5.173 3.626-8.693h3.556c0 3.52-1.565 8.089-4.694 11.182-6.24 6.187-16.373 6.187-22.613 0-6.222-6.169-6.275-16.196-.035-22.364 6.24-6.17 16.248-6.17 22.489 0L43.997 12v12.658zm-15.111-3.77v7.556l6.222 3.698-1.28 2.151-7.609-4.515v-8.89h2.667z\" fill=\"#3D00EC\"/></g>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});