define("ember-svg-jar/inlined/detection-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"nonzero\" d=\"M32.187 8.336a2.812 2.812 0 00-2.442 4.208l-4.715 7.062a2.787 2.787 0 00-1.211.115l-3.952-3.97a2.812 2.812 0 10-5.198.4l-6.543 9.076a2.812 2.812 0 102.21 1.552l6.473-9.096c.41.057.828.022 1.223-.103l3.973 3.968a2.812 2.812 0 105.151-.5l4.76-7.103a2.812 2.812 0 10.271-5.612v.003z\" id=\"path-1\"/>",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
  _exports.default = _default;
});