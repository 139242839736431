define("@loom/ember-dropdown/templates/components/dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9FbS3a35",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"data-test-trigger-text\",\"\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n\"],[1,[28,\"component\",[[24,[\"_svgComponent\"]]],[[\"data-test-trigger-svg\",\"class\"],[true,[28,\"concat\",[\"dropdown-menu__trigger__icon\",\" \",[24,[\"iconCls\"]],\" \",[28,\"if\",[[24,[\"dropdown\",\"isOpen\"]],\"dropdown-menu__trigger__icon--flip\"],null]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-dropdown/templates/components/dropdown-trigger.hbs"
    }
  });

  _exports.default = _default;
});