define("ember-svg-jar/inlined/hamburger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 2.5V.833h20V2.5H0zm20 8.667H0V9.5h20v1.667z\"/>",
    "attrs": {
      "height": "12",
      "viewBox": "0 0 20 12"
    }
  };
  _exports.default = _default;
});