define("ember-svg-jar/inlined/no-device-costs-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"path-1\" d=\"M0 0h32v32H0z\"/></defs><g id=\"Landing-Page\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g id=\"Landing-Page-Copy-30\" transform=\"translate(-1028 -1115)\"><g id=\"Our-solution-benefits\" transform=\"translate(120 1046)\"><g id=\"No_Device_Costs_Icon\" transform=\"translate(902 65)\"><mask id=\"mask-2\" fill=\"#fff\"><use xlink:href=\"#path-1\"/></mask><g id=\"money_off-24px\" mask=\"url(#mask-2)\"><path id=\"Path\" d=\"M0 0h32v32H0z\"/><path d=\"M17.161 9.2c2.354 0 3.227 1.133 3.306 2.8h2.923c-.093-2.293-1.481-4.4-4.245-5.08V4h-3.967v2.88c-.701.16-1.363.4-1.958.72l1.944 1.96c.543-.227 1.204-.36 1.997-.36zM7.68 5.413L6 7.107l4.55 4.586c0 2.774 2.062 4.28 5.17 5.214l4.641 4.68c-.45.64-1.388 1.213-3.2 1.213-2.724 0-3.795-1.227-3.94-2.8h-2.91c.159 2.92 2.328 4.56 4.867 5.107V28h3.967v-2.867c1.27-.24 2.407-.733 3.24-1.493l2.936 2.96L27 24.907 7.68 5.413z\" id=\"Shape\" fill=\"#000\" fill-rule=\"nonzero\"/></g></g></g></g></g>",
    "attrs": {
      "width": "21",
      "height": "24",
      "viewBox": "0 0 21 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});