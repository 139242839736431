define("@loom/ember-chrome/components/app-header-section", ["exports", "@loom/ember-chrome/templates/components/app-header-section", "@loom/ember-styles/macros/bem-class", "@loom/ember-chrome/components/app-header", "@loom/ember-styles/macros/palettes", "@loom/ember-styles/macros/layouts"], function (_exports, _appHeaderSection, _bemClass, _appHeader, _palettes, _layouts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class App Header Section
   * @description A required component to be rendered inside of `{{appHeader}}`. See `{{appHeader}}` for more information.
   */
  var _default = Ember.Component.extend({
    layout: _appHeaderSection.default,
    classNames: ['app-header__section'],
    classNameBindings: ['paletteCls', 'alignCls'],

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the header section. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the header section. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark'),
    paletteCls: (0, _palettes.paletteCls)(),

    /**
     * @argument align
     * @description `left || center || right`. Optional. Defaults to `left`.
     * @type {string}
     */
    align: (0, _layouts.align)('left'),
    alignCls: (0, _bemClass.default)('app-header__section', 'modifier', 'align'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (false && !(this.header instanceof _appHeader.default) && Ember.assert('app-header-section must be rendered inside of an app-header', this.header instanceof _appHeader.default));
      this.header.onContentAdded(Ember.get(this, 'align'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.header) {
        this.header.onContentRemoved(Ember.get(this, 'align'));
      }
    }
  });

  _exports.default = _default;
});