define("ember-svg-jar/inlined/mobile-arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z\" fill=\"#000\"/>",
    "attrs": {
      "width": "12",
      "height": "8",
      "viewBox": "0 0 12 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});