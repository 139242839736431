define("ember-svg-jar/inlined/email-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.8 0h14.4c.99 0 1.8.81 1.8 1.8v10.8c0 .99-.81 1.8-1.8 1.8H1.8c-.99 0-1.8-.81-1.8-1.8L.009 1.8C.009.81.81 0 1.8 0zM9 8.1l7.2-4.5V1.8L9 6.3 1.8 1.8v1.8L9 8.1z\" fill=\"#000\"/>",
    "attrs": {
      "width": "18",
      "height": "15",
      "viewBox": "0 0 18 15",
      "fill": "none"
    }
  };
  _exports.default = _default;
});