define("ember-intl/hydrate", ["exports", "ember-intl/utils/links"], function (_exports, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookupByFactoryType = lookupByFactoryType;
  _exports.default = _default;

  /* globals requirejs */

  /** @private **/
  function lookupByFactoryType(type, modulePrefix) {
    return Object.keys(requirejs.entries).filter(function (key) {
      return key.indexOf("".concat(modulePrefix, "/").concat(type, "/")) === 0;
    });
  }
  /**
   * Peeks into the requirejs map and registers all locale data (CLDR & Translations) found.
   *
   * @private
   */


  function _default(service, owner) {
    var config = owner.resolveRegistration('config:environment');
    var cldrs = lookupByFactoryType('cldrs', config.modulePrefix);
    var translations = lookupByFactoryType('translations', config.modulePrefix);

    if (!cldrs.length) {
      (false && Ember.warn("[ember-intl] project is missing CLDR data\nIf you are asynchronously loading translation,\n      see: ".concat(_links.default.asyncTranslations, "."), false, {
        id: 'ember-intl-missing-cldr-data'
      }));
    }

    cldrs.map(function (moduleName) {
      return owner.resolveRegistration("cldr:".concat(moduleName.split('/').pop()));
    }).forEach(function (data) {
      return data.forEach(service.addLocaleData);
    });
    translations.forEach(function (moduleName) {
      var localeName = moduleName.split('/').pop();
      service.addTranslations(localeName, owner.resolveRegistration("translation:".concat(localeName)));
    });
  }
});