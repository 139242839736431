define("ember-svg-jar/inlined/placeholder-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M536 622V0H59.556 0v622h536zM162.75 361.589l74.107 103.18 103.75-154.598L474 515.845H59l103.75-154.256z\" fill=\"#202226\"/>",
    "attrs": {
      "width": "536",
      "height": "622",
      "viewBox": "0 0 536 622",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});