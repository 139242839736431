define("@loom/ember-chrome/templates/components/app-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0EOvZVhS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-sidebar__overlay\"],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-sidebar__container\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"header\",\"body\",\"footer\",\"isCollapsed\"],[[28,\"component\",[\"app-sidebar-header\"],[[\"sideBar\",\"palette\"],[[23,0,[]],[24,[\"palette\"]]]]],[28,\"component\",[\"app-sidebar-body\"],[[\"sideBar\",\"palette\"],[[23,0,[]],[24,[\"palette\"]]]]],[28,\"component\",[\"app-sidebar-footer\"],[[\"sideBar\",\"palette\"],[[23,0,[]],[24,[\"palette\"]]]]],[24,[\"isCollapsed\"]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@loom/ember-chrome/templates/components/app-sidebar.hbs"
    }
  });

  _exports.default = _default;
});