define("ember-svg-jar/inlined/US", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0)\"><path d=\"M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z\" fill=\"#F0F0F0\"/><path d=\"M9.566 9.998h10.435c0-.902-.12-1.777-.344-2.608H9.567v2.608zM9.566 4.782h8.967a10.051 10.051 0 00-2.307-2.609h-6.66v2.609zM10.002 19.999a9.957 9.957 0 006.225-2.174H3.777a9.957 9.957 0 006.225 2.174zM1.468 15.217h17.065a9.94 9.94 0 001.123-2.609H.346a9.94 9.94 0 001.122 2.609z\" fill=\"#D80027\"/><path d=\"M4.632 1.562h.911l-.847.615.324.997-.848-.616-.848.616.28-.86c-.746.62-1.4 1.35-1.94 2.16h.293l-.54.393c-.084.14-.165.282-.242.427l.258.793-.481-.35c-.12.254-.229.513-.327.777l.284.874h1.048l-.848.616.324.996-.848-.616-.508.37C.027 9.161 0 9.577 0 10h10V0a9.953 9.953 0 00-5.368 1.562zM5.02 9l-.848-.616L3.324 9l.324-.996-.847-.616h1.047l.324-.997.324.997h1.047l-.847.616L5.02 9zm-.324-3.91l.324.997-.848-.616-.848.616.324-.996-.847-.616h1.047l.324-.997.324.997h1.047l-.847.616zM8.606 9l-.847-.616L6.91 9l.324-.996-.848-.616h1.048l.324-.997.324.997H9.13l-.847.616.324.996zm-.323-3.91l.324.997-.848-.616-.848.616.324-.996-.848-.616h1.048l.324-.997.324.997H9.13l-.847.616zm0-2.913l.324.997-.848-.616-.848.616.324-.997-.848-.615h1.048L7.76.565l.324.997H9.13l-.847.615z\" fill=\"#0052B4\"/></g><defs><clipPath id=\"clip0\"><path fill=\"#fff\" d=\"M0 0h20v20H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});