define("ember-svg-jar/inlined/minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.286 3.286H18V.714H0v2.572h10.286z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "18",
      "height": "4",
      "viewBox": "0 0 18 4",
      "fill": "none"
    }
  };
  _exports.default = _default;
});