define("ember-svg-jar/inlined/tc-logo-dark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><filter x=\"-9.1%\" y=\"-10.4%\" width=\"118.2%\" height=\"120.9%\" filterUnits=\"objectBoundingBox\" id=\"filter-tc-logo-dark\"><feOffset dy=\"2\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\"2\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0\" in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></defs><g filter=\"url(#filter-tc-logo-dark)\" transform=\"translate(4 2)\" fill=\"#202226\" fill-rule=\"evenodd\"><path d=\"M0 0v33.6h48.299V115H82V33.6H65.15V0zM115 17h17V0h-17z\"/><path d=\"M65 17h17V0H65zM82 34h17V17H82zM65 33h8v-8h-8zM91 8h8V0h-8zM107 25h8v-8h-8z\"/></g>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 140 123",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});