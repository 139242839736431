define("ember-svg-jar/inlined/performance-data-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FF00A8\" fill-opacity=\".15\" d=\"M0 0h56v56H0z\"/><g clip-path=\"url(#clip0)\" fill=\"#FF00A8\"><path d=\"M40.566 21.293h3.6v24h-3.6v-24zm-18.4 11.932h3.6v12.068h-3.6V33.225zm9.2-5.966h3.6v18.034h-3.6V27.259zM16.266 39.225h-3.6v6.068h3.6v-6.068zM39.423 14.277l-2.147 5.732-3.974-5.675 6.121-.057z\"/><path d=\"M35.175 16.03l1.147 1.638L13.868 33.39l-1.147-1.639z\"/></g>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});