define("ember-svg-jar/inlined/new-tab-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.833 8.833c0-.458.375-.833.834-.833.458 0 .833.375.833.833v5c0 .917-.75 1.667-1.667 1.667H2.167C1.25 15.5.5 14.75.5 13.833V2.167C.5 1.25 1.25.5 2.167.5h5C7.625.5 8 .875 8 1.333a.836.836 0 01-.833.834h-5v11.666h11.666v-5zM10.675.5h4.408c.234 0 .417.183.417.417v4.408c0 .375-.45.558-.717.3l-1.616-1.617L6.675 10.5A.83.83 0 115.5 9.325l6.5-6.5-1.617-1.617A.412.412 0 0110.675.5z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});