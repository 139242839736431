define("ember-svg-jar/inlined/no-device-install-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"path-1\" d=\"M0 0h32v32H0z\"/></defs><g id=\"Landing-Page\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g id=\"Landing-Page-Copy-30\" transform=\"translate(-702 -1291)\"><g id=\"Our-solution-benefits\" transform=\"translate(120 1046)\"><g id=\"No_Device_Install_Icon\" transform=\"translate(580 241)\"><mask id=\"mask-2\" fill=\"#fff\"><use xlink:href=\"#path-1\"/></mask><g id=\"Group-17\" mask=\"url(#mask-2)\"><g transform=\"translate(2 4)\"><g id=\"hand\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M10.325 6.676l2.538 2.53-.51 1.405 1.018.371.338-.931 9.304 9.277-1.78 4.892a4.346 4.346 0 01-5.553 2.59l-7.432-2.705a4.326 4.326 0 01-2.46-2.268L.753 10.805s1.739-.785 1.787-.79c.294-.113.606-.114.912-.003.224.081.405.217.551.385.037.025 3.476 4.101 3.476 4.101l2.846-7.822zm12.35-3.836a1.623 1.623 0 01.971 2.083l-3.15 8.653 1.019.37 2.037-5.599a1.623 1.623 0 012.083-.971 1.623 1.623 0 01.971 2.083l-2.86 7.854-1.744-1.741-4.176-4.163 2.766-7.598a1.623 1.623 0 012.083-.971zM18.973.34a1.623 1.623 0 01.971 2.083l-2.964 8.143-2.538-2.53L16.89 1.31A1.623 1.623 0 0118.973.34zm-4.998 1.063a1.623 1.623 0 01.971 2.082l-1.35 3.708-2.428-2.421-.11-.11.834-2.288a1.623 1.623 0 012.083-.971z\" id=\"Combined-Shape\" fill=\"#000\" fill-rule=\"nonzero\"/></g><path id=\"Shape\" fill=\"#000\" fill-rule=\"nonzero\" d=\"M14.635 10.96l-.585-.583-2.665-2.657-1.668-1.663-3.922-3.91L4.593.948 3.217 2.32l2.177 2.17 14.073 14.03 6.207 6.188 1.376-1.371-4.832-4.817z\"/></g></g></g></g></g></g>",
    "attrs": {
      "width": "27",
      "height": "28",
      "viewBox": "0 0 27 28",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});