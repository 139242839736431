define("ember-svg-jar/inlined/AU", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10c0 .002 10-9.999 10-10 5.523 0 10 4.477 10 10z\" fill=\"#0052B4\"/><path d=\"M10 0h-.002H10zM9.974 10H10v-.027a9.648 9.648 0 01-.026.027z\" fill=\"#F0F0F0\"/><path d=\"M10 5.218V.001h-.002C4.476.001 0 4.479 0 10.001h5.217V7.063l2.938 2.938h1.818L10 9.974V8.156L7.062 5.218H10z\" fill=\"#F0F0F0\"/><path d=\"M5.06 1.305A10.048 10.048 0 001.305 5.06V10h2.608V3.913H10V1.305H5.06z\" fill=\"#D80027\"/><path d=\"M10 8.77L6.446 5.218h-1.23L9.999 10V8.77z\" fill=\"#D80027\"/><path d=\"M6.03 11.74l.55 1.147 1.239-.286-.555 1.144.996.79-1.24.28.003 1.272-.992-.795-.993.795.004-1.272-1.241-.28.996-.79-.555-1.144 1.24.286.549-1.147zM14.97 13.913l.275.574.62-.143-.278.572.499.395-.62.14.001.636-.496-.398-.496.398.001-.636-.62-.14.498-.395-.277-.572.62.143.274-.574zM12.42 7.826l.274.574.62-.143-.278.572.498.395-.62.14.002.636-.496-.398-.497.398.002-.636-.62-.14.498-.395-.278-.572.62.143.275-.574zM14.97 4.348l.275.574.62-.143-.278.572.499.395-.62.14.001.636-.496-.398-.496.398.001-.636-.62-.14.498-.395-.277-.572.62.143.274-.574zM17.202 6.957l.274.573.62-.143-.278.572.499.396-.62.14.001.636-.496-.398-.496.398.001-.636-.62-.14.498-.396-.277-.572.62.143.274-.573zM15.608 10l.215.664h.699l-.565.41.216.665-.565-.41-.565.41.215-.664-.565-.41h.699l.216-.665z\" fill=\"#F0F0F0\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});