define("@loom/ember-macros/macros/computed-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var computedOption = function computedOption(def) {
    return def;
  };

  Ember.runInDebug(function () {
    computedOption = function computedOption(def, allowed) {
      var checkVal = function checkVal() {};

      Ember.runInDebug(function () {
        var allowedSet = allowed instanceof Set ? allowed : new Set(allowed);

        var allowedStr = _toConsumableArray(allowedSet).join(',');

        (false && !(allowedSet.has(def)) && Ember.assert("default must be in {".concat(allowedStr, "}, was ").concat(def), allowedSet.has(def)));

        checkVal = function checkVal(key, val) {
          (false && !(allowedSet.has(val)) && Ember.assert("".concat(key, " must be in {").concat(allowedStr, "}, was ").concat(val), allowedSet.has(val)));
        };
      });
      return Ember.computed({
        get: function get() {
          return def;
        },
        set: function set(key, val) {
          checkVal(key, val);
          return val;
        }
      });
    };
  });
  var _default = computedOption;
  _exports.default = _default;
});