define("@loom/ember-svgs/components/svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COMPONENT_PREFIX = 'component:svg-';
  var COMPONENT_POSTFIX = '::ember';
  /**
   * @class SVG
   * @description Commonly used SVGs
    ```handlebars
    {{svg-add}}
    {{svg-arrow-alt}}
    {{svg-arrow-down}}
    {{svg-checkbox}}
    {{svg-checkmark}}
    {{svg-close}}
    {{svg-dash}}
    {{svg-disclose}}
    {{svg-search}}
    {{svg-spinner}}
    {{svg-toast-danger}}
    {{svg-toast-info}}
    {{svg-toast-success}}
    {{svg-toast-warning}}
    ```
   */

  var _default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['icon'],
    classNameBindings: ['iconName'],
    attributeBindings: ['viewBox', 'width', 'height', 'iconName:data-icon'],
    init: function init() {
      this._super.apply(this, arguments);

      var str = this.toString();
      this.iconName = str.slice(str.indexOf(COMPONENT_PREFIX) + COMPONENT_PREFIX.length, str.lastIndexOf(COMPONENT_POSTFIX));
    }
  });

  _exports.default = _default;
});