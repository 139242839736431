define("@loom/ember-styles/utils/layout-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BUTTON_DISABLED_TYPES = _exports.BUTTON_GROUP_CHILDREN = _exports.BUTTON_ICON_POSITIONS = _exports.BUTTON_SIZES = _exports.FLOW_OPTS = _exports.PADDING_SIZES = _exports.ALIGN_OPTS = void 0;
  var ALIGN_OPTS = new Set(['left', 'center', 'right']);
  _exports.ALIGN_OPTS = ALIGN_OPTS;
  var PADDING_SIZES = new Set(['none', 'x-small', 'small', 'semi-small', 'default', 'semi-large', 'large', 'x-large']);
  _exports.PADDING_SIZES = PADDING_SIZES;
  var FLOW_OPTS = new Set(['row', 'column']);
  _exports.FLOW_OPTS = FLOW_OPTS;
  var BUTTON_SIZES = new Set(['small', 'medium', 'large']);
  _exports.BUTTON_SIZES = BUTTON_SIZES;
  var BUTTON_ICON_POSITIONS = new Set(['before', 'after']);
  _exports.BUTTON_ICON_POSITIONS = BUTTON_ICON_POSITIONS;
  var BUTTON_GROUP_CHILDREN = new Set(['inline', 'flex-grow']);
  _exports.BUTTON_GROUP_CHILDREN = BUTTON_GROUP_CHILDREN;
  var BUTTON_DISABLED_TYPES = new Set(['grey', 'opacity']);
  _exports.BUTTON_DISABLED_TYPES = BUTTON_DISABLED_TYPES;
});