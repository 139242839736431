define("ember-test-waiters/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  /**
   * A class representing a test waiter token.
   *
   * @public
   * @class
   */
  var Token = function Token() {
    _classCallCheck(this, Token);
  };

  _exports.default = Token;
});