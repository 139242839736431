define("ember-metrics/utils/remove-from-dom", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = removeFromDOM;
  function removeFromDOM(script) {
    document.querySelectorAll(script).forEach(function (el) {
      el.parentElement.removeChild(el);
    });
  }
});