define("@loom/ember-dropdown/components/dropdown-content", ["exports", "ember-basic-dropdown/components/basic-dropdown/content", "@loom/ember-dropdown/templates/components/dropdown-content", "@loom/ember-styles/macros/palettes"], function (_exports, _content, _dropdownContent, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dropdown Content
   * @description This is the inner component of the Dropdown Menu component.
   * It is the actual dropdown content which will wrap
   * the dropdown content items.
   */
  var _default = _content.default.extend({
    layout: _dropdownContent.default,
    _contentTagName: 'ul',

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the dropdown content. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the dropdown
     * menu content. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark')
  });

  _exports.default = _default;
});