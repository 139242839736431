define("ember-svg-jar/inlined/engine-oil-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M42.935 22.23c-1.41 0-4.732 7.723-4.732 8.947a4.738 4.738 0 004.732 4.731 4.739 4.739 0 004.732-4.732c0-1.223-3.322-8.945-4.732-8.945zm0 11.678a2.736 2.736 0 01-2.732-2.732c0-1.111 1.664-4.253 2.732-6.075 1.068 1.822 2.732 4.963 2.732 6.076a2.736 2.736 0 01-2.732 2.731zM40.079 17.796l2.16 2.116a.992.992 0 00.7.286 1 1 0 00.698-1.714l-2.56-2.51a.991.991 0 00-.946-.256l-4.834 1.224c-.014.002-.028-.006-.042-.002l-11.49 2.838-1.738-1.53a.998.998 0 00-.66-.248h-5.394v-2h2a1 1 0 000-2h-6a1 1 0 000 2h2v2h-5.05c-.338-1.082-1.186-1.968-2.376-2.396l-3.848-1.106a1 1 0 10-.554 1.922l3.786 1.084c.702.256 1.156.874 1.156 1.576 0 .062.006.124.016.182l.872 11.812c.04.522.476.926.998.926h16.142c1.252 0 2.408-.596 3.086-1.596l7.896-11.6 3.982-1.008zm-13.53 11.482c-.308.452-.842.722-1.434.722H9.903l-.738-10H20.99l1.848 1.626c.246.216.582.296.902.22l9.46-2.338-6.652 9.77z\" fill=\"#58595B\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});