define("@loom/ember-chrome/components/app-chrome", ["exports", "@loom/ember-styles/macros/palettes", "@loom/ember-chrome/templates/components/app-chrome"], function (_exports, _palettes, _appChrome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class App Chrome
   * @description A high level component that wraps all of the application UI. 
   * All attributes are available to any component rendered inside of it through the yielded `appChrome` object.
    ```handlebars
    {{#app-chrome appNameKey='app.name' palette='tertiary' currentPath=currentPath as |appChrome|}}
      {{!-- everything goes here --}}
    {{/app-chrome}}
    ```
   */
  var _default = Ember.Component.extend({
    layout: _appChrome.default,
    intl: Ember.inject.service(),
    classNames: ['app-chrome'],
    classNameBindings: ['paletteCls', 'sideBarIsOpen', 'sideBarIsCollapsed'],

    /**
     * @private
     */
    sideBarIsOpen: false,

    /**
     * @private
     */
    sideBarIsCollapsed: false,

    /**
     * @argument sideBarType
     * @description `off-canvas || fixed || collapseable`. Sets the sidebar type. Optional. Defaults to `null`.
     * @type {string}
     */
    sideBarType: null,

    /**
     * @private
     */
    hasSidebar: false,

    /**
     * @argument appNameKey
     * @description An i18n path to the name of the application. Optional. Defaults to `null`.
     * @type {string}
     */
    appNameKey: null,

    /**
     * @argument currentPath
     * @description Should be set to `currentPath` from the application controller. Optional. Defaults to `null`.
     * @type {string}
     */
    currentPath: null,

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Color of the chrome. Optional. Defaults to `primary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('primary'),

    /**
     * @argument shade
     * @description `light || base || dark`. Optional. Shade of the color of the chrome. Defaults to `dark`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('dark'),
    paletteCls: (0, _palettes.paletteCls)(),
    displayName: Ember.computed('intl.locale', 'currentPath', 'appNameKey', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'intl', 'currentPath', 'appNameKey'),
          intl = _EmberGetProperties.intl,
          currentPath = _EmberGetProperties.currentPath,
          appNameKey = _EmberGetProperties.appNameKey;

      if (currentPath) {
        var curPathName = "".concat(Ember.get(this, 'currentPath'), ".name");

        if (intl.exists(curPathName)) {
          return intl.t(curPathName);
        } // look for names for all ancestor routes


        for (var lastDot = currentPath.lastIndexOf('.'); lastDot > 0; lastDot = currentPath.lastIndexOf('.', lastDot - 1)) {
          var key = "".concat(currentPath.slice(0, lastDot), ".name");

          if (intl.exists(key)) {
            return intl.t(key);
          }
        }
      }

      if (appNameKey && intl.exists(appNameKey)) {
        return intl.t(appNameKey);
      }

      return null;
    }).readOnly(),

    /**
     * @private
     */
    onContentAdded: function onContentAdded(type, options) {
      if (type === 'sidebar') {
        // assert('App Chrome can only have one sidebar', !this.hasSidebar);
        this.hasSidebar = true;
        Ember.set(this, 'sideBarType', options.type);
      }
    },

    /**
     * @private
     */
    onContentRemoved: function onContentRemoved(type) {
      if (type === 'sidebar') {
        this.hasSidebar = false;
        Ember.set(this, 'sideBarType', null);
      }
    }
  });

  _exports.default = _default;
});