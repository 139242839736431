define("@loom/ember-buttons/components/button-basic", ["exports", "@loom/ember-styles/macros/palettes", "@loom/ember-styles/macros/bem-class", "@loom/ember-styles/utils/layout-options", "@loom/ember-macros/macros/computed-option", "@loom/ember-buttons/templates/components/button-basic"], function (_exports, _palettes, _bemClass, _layoutOptions, _computedOption, _buttonBasic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Button Basic
   * @description This component renders a customizable button and takes an action.
   * If used inside a form, it will behave
   * as if it were a submit button.
    ```handlebars
    {{button-basic
      text='Add Item'
      icon='svg-add'
      action=(action 'myAction')}}
    ```
    If you need to perform an async action, use `{{button-async}}`.
  
    If you want to use the button to link to another route, use `{{button-link}}`.
   */
  var ButtonBasicComponent = Ember.Component.extend({
    layout: _buttonBasic.default,
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['colorCls', 'fillCls', 'sizeCls', 'iconCls', 'widthCls', 'disabledCls', 'rounded:btn--rounded'],
    attributeBindings: ['type', '_disabled:disabled'],
    type: 'button',

    /**
     * @argument text
     * @description The button text. Should be i18n string.
     * Optional if `icon` is set. Required if no `icon`. Defaults to `null`.
     * @type {string}
     */
    text: null,
    _text: Ember.computed.readOnly('text'),

    /**
     * @argument palette
     * @description `primary || secondary || tertiary || negative || alert || background`.
     * Sets the color of the button. Optional. Defaults to `secondary`.
     * @type {string}
     */
    palette: (0, _palettes.palette)('secondary'),
    _palette: Ember.computed.readOnly('palette'),

    /**
     * @argument shade
     * @description `light || base || dark`. Sets the shade of the color of the button and
     * chooses an appropriate text color. Optional. Defaults to `base`.
     * @type {string}
     */
    shade: (0, _palettes.paletteShade)('base'),
    _shade: Ember.computed.readOnly('shade'),

    /**
     * @argument fill
     * @description `solid || outline`. Optional. Defaults to `solid`.
     * @type {string}
     */
    fill: (0, _computedOption.default)('solid', ['solid', 'outline']),
    _fill: Ember.computed.readOnly('fill'),

    /**
     * @argument size
     * @description `small || medium || large`. Determines the size of the button. Optional. Defaults to `medium`.
     * @type {string}
     */
    size: (0, _computedOption.default)('medium', _layoutOptions.BUTTON_SIZES),
    _size: Ember.computed.readOnly('size'),

    /**
     * @argument width
     * @description `full`. Optional. Defaults to `null`.
     * @type {string}
     */
    width: (0, _computedOption.default)(null, [null, 'full']),
    _width: Ember.computed.readOnly('width'),

    /**
     * @argument icon
     * @description File name of svg icon.
     * Optional if `text` is set. Requried if no `text` is set. Defaults to `null`.
     * @type {string}
     */
    icon: null,
    _icon: Ember.computed.readOnly('icon'),

    /**
     * @argument iconPosition
     * @description `before || after`. If an icon is set, the icon can be positioned before or after text.
     * Optional. Defaults to `after`;
     * @type {string}
     */
    iconPosition: (0, _computedOption.default)('after', _layoutOptions.BUTTON_ICON_POSITIONS),
    _iconPosition: Ember.computed.readOnly('iconPosition'),

    /**
     * @argument rounded
     * @description `true || false`. If true, buttons are given a very rounded button.
     * Optional. Defaults to `false`.
     * @type {boolean}
     */
    rounded: false,

    /**
     * @argument action
     * @description The action to take on click. Optional. Defaults to `null`.
     * @type {function}
     */
    action: null,

    /**
     * @argument disabled
     * @description `true || false`. Disables or enables the click of the button. Optional. Defaults to `false`.
     * @type {boolean}
     */
    disabled: false,
    _disabled: Ember.computed.readOnly('disabled'),

    /**
     * @argument disabledType
     * @description `'grey' | 'opacity'`. Decides whether the disabled button takes on a grey state,
     * or a lowered opacity state. Defaults to `grey`.
     * @type {string}
     */
    disabledType: (0, _computedOption.default)('grey', _layoutOptions.BUTTON_DISABLED_TYPES),
    iconOnly: Ember.computed('_icon', '_text', function () {
      var _EmberGetProperties = Ember.getProperties(this, '_icon', '_text'),
          icon = _EmberGetProperties._icon,
          text = _EmberGetProperties._text;

      return icon && !text;
    }),
    iconBefore: Ember.computed('_icon', '_iconPosition', function () {
      var _EmberGetProperties2 = Ember.getProperties(this, '_icon', '_iconPosition'),
          icon = _EmberGetProperties2._icon,
          iconPosition = _EmberGetProperties2._iconPosition;

      return icon && iconPosition === 'before';
    }),
    iconAfter: Ember.computed('_icon', '_iconPosition', function () {
      var _EmberGetProperties3 = Ember.getProperties(this, '_icon', '_iconPosition'),
          icon = _EmberGetProperties3._icon,
          iconPosition = _EmberGetProperties3._iconPosition;

      return icon && iconPosition === 'after';
    }),
    iconCls: Ember.computed('_icon', '_text', '_iconPosition', function () {
      var _EmberGetProperties4 = Ember.getProperties(this, '_icon', '_text', '_iconPosition'),
          icon = _EmberGetProperties4._icon,
          text = _EmberGetProperties4._text,
          iconPosition = _EmberGetProperties4._iconPosition;

      var iconOnly = icon && !text;
      var iconNone = !icon && text;

      if (iconOnly) {
        return 'btn--icon-only';
      }

      if (iconNone) {
        return 'btn--icon-none';
      }

      return "btn--icon-".concat(iconPosition);
    }),
    sizeCls: (0, _bemClass.default)('btn', 'modifier', '_size'),
    fillCls: (0, _bemClass.default)('btn', 'modifier', '_fill'),
    widthCls: (0, _bemClass.default)('btn', 'modifier', '_width'),
    colorCls: Ember.computed('_palette', '_shade', function () {
      return "btn--".concat(Ember.get(this, '_palette'), "--").concat(Ember.get(this, '_shade'));
    }),
    disabledCls: Ember.computed('disabled', 'disabledType', function () {
      var _EmberGetProperties5 = Ember.getProperties(this, 'disabled', 'disabledType'),
          disabled = _EmberGetProperties5.disabled,
          disabledType = _EmberGetProperties5.disabledType;

      if (disabled) {
        return "btn--disabled--".concat(disabledType);
      }

      return '';
    }),
    click: function click(event) {
      var _EmberGetProperties6 = Ember.getProperties(this, 'type', 'action'),
          type = _EmberGetProperties6.type,
          action = _EmberGetProperties6.action;

      if (type !== 'submit') {
        event.preventDefault();

        if (Ember.typeOf(action) === 'function') {
          action();
        }
      }
    }
  });
  Ember.runInDebug(function () {
    ButtonBasicComponent.reopen({
      _assertProps: Ember.computed('_icon', '_text', 'action', function () {
        var _EmberGetProperties7 = Ember.getProperties(this, '_icon', '_text', 'action'),
            icon = _EmberGetProperties7._icon,
            text = _EmberGetProperties7._text,
            action = _EmberGetProperties7.action;

        (false && !(Ember.isEmpty(action) || Ember.typeOf(action) === 'function') && Ember.assert('action must be a function', Ember.isEmpty(action) || Ember.typeOf(action) === 'function'));
        (false && !(Ember.isPresent(icon) || Ember.isPresent(text)) && Ember.assert('text or icon is required', Ember.isPresent(icon) || Ember.isPresent(text)));
      })
    });
  });
  var _default = ButtonBasicComponent;
  _exports.default = _default;
});