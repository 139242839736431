define("@loom/ember-styles/macros/bem-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bemClass;
  var TYPES = Object.create(null, {
    element: {
      value: true
    },
    modifier: {
      value: true
    }
  });

  function bemClass(block, type, compute) {
    (false && !(Ember.typeOf(block) === 'string') && Ember.assert("block must be a string, was \"".concat(block, "\""), Ember.typeOf(block) === 'string'));
    (false && !(TYPES[type]) && Ember.assert("type must be one of {element,modifier}, was \"".concat(type, "\""), TYPES[type]));
    (false && !(Ember.typeOf(compute) === 'string') && Ember.assert("computed must be a string, was \"".concat(compute, "\""), Ember.typeOf(compute) === 'string'));
    var separator = '__';

    if (type === 'modifier') {
      separator = '--';
    }

    return Ember.computed(compute, function () {
      var val = Ember.get(this, compute);

      if (Ember.isPresent(val)) {
        return "".concat(block).concat(separator).concat(val);
      }

      return null;
    });
  }
});