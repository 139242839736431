define("ember-svg-jar/inlined/braking-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.667 19.8c0-3.75-2.99-6.8-6.667-6.8-3.676 0-6.667 3.05-6.667 6.8s2.99 6.8 6.667 6.8c3.676 0 6.667-3.05 6.667-6.8zm-13.39-5.505a.814.814 0 01-.134.825c-1.156 1.34-1.813 3.121-1.813 5.023 0 1.613.472 3.141 1.32 4.381.156.21.198.5.108.755a.682.682 0 01-.54.466.63.63 0 01-.613-.297C10.571 23.937 10 22.086 10 20.143c0-2.291.795-4.448 2.184-6.059a.636.636 0 01.454-.233c.274-.014.527.162.638.444zm14.535-.214C29.205 15.695 30 17.85 30 20.143c0 1.943-.571 3.794-1.596 5.291a.638.638 0 01-.622.31.682.682 0 01-.54-.465.813.813 0 01.099-.742c.857-1.253 1.329-2.781 1.329-4.394 0-1.902-.657-3.683-1.809-5.018a.815.815 0 01-.142-.82c.106-.28.352-.46.623-.455a.63.63 0 01.47.23zm-8.182 1.186h.74c.205 0 .37.169.37.377v4.912a.374.374 0 01-.37.377h-.74a.374.374 0 01-.37-.377v-4.912c0-.208.165-.377.37-.377zm1.11 7.933a.374.374 0 00-.37-.378h-.74c-.205 0-.37.17-.37.378v.756c0 .208.165.377.37.377h.74c.205 0 .37-.169.37-.377V23.2z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});