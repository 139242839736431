define("ember-svg-jar/inlined/driver-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.972 1.995c-5.51 0-9.977 4.467-9.977 9.977 0 5.51 4.467 9.977 9.977 9.977 5.51 0 9.977-4.467 9.977-9.977 0-5.51-4.467-9.977-9.977-9.977zm0 1.996a7.985 7.985 0 017.73 5.983 15.986 15.986 0 00-15.46 0 7.985 7.985 0 017.73-5.983zM9.91 19.684a8 8 0 01-5.245-4.495 3.993 3.993 0 015.244 4.495h.001zm2.062-5.717a1.995 1.995 0 110-3.99 1.995 1.995 0 010 3.99zm2.062 5.717a3.993 3.993 0 015.244-4.495 7.999 7.999 0 01-5.244 4.495z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});