define("ember-svg-jar/inlined/acceleration-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.036 12C14.44 12 10 16.515 10 21.964c0 1.698.428 3.326 1.198 4.719l.024.072.048.095c.257.521.865.767 1.413.575h.024a.432.432 0 00.24-.072l1.58-.886a.435.435 0 00.144-.072.433.433 0 00.048-.527l-.264-.455a.444.444 0 00-.263-.212.438.438 0 00-.336.044l-.623.36a7.417 7.417 0 01-.934-3.64c0-1.157.26-2.25.719-3.235l.55.312a.38.38 0 00.097.072.432.432 0 00.503-.216l.263-.455a.433.433 0 00-.144-.575l-.574-.335a7.656 7.656 0 015.58-3.186v1.03c0 .24.192.431.432.431h.55a.43.43 0 00.432-.43v-1.03a7.717 7.717 0 015.556 3.185l-.622.36h-.024a.435.435 0 00-.186.275c-.02.11.003.227.066.323l.264.455c.056.105.15.18.263.213.114.03.233.015.335-.045l.623-.384c.464.986.719 2.084.719 3.234 0 1.26-.372 2.497-.959 3.64l-.622-.334a.433.433 0 00-.144-.072.432.432 0 00-.455.215l-.264.48a.432.432 0 00.168.574l1.581.91a.435.435 0 00.311.048c.36.099.743.018 1.03-.215.051-.036.1-.075.144-.12.015-.01.033-.015.048-.024v-.024l.072-.072v-.024c.045-.045.084-.093.12-.144.757-1.428 1.269-3.075 1.269-4.838C30 16.503 25.497 12 20.036 12zm-.072 8.431a1.532 1.532 0 100 3.066c.422 0 .8-.177 1.078-.455 1.395-.108 5.108-.392 5.365-.455.306-.078.527-.246.527-.551-.078-.383-.293-.599-.599-.599-.128 0-3.862-.386-5.27-.527a1.521 1.521 0 00-1.1-.479zm-2.443 6.132a.766.766 0 00-.623.766v.767c0 .422.345.766.767.766h4.599a.768.768 0 00.766-.766v-.767a.768.768 0 00-.767-.766h-4.742z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});