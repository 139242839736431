define("ember-svg-jar/inlined/fuel-levels-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E8E8E8\" d=\"M0 0h40v40H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M27.77 15.23l.01-.01-3.72-3.72L23 12.56l2.11 2.11c-1.05.4-1.76 1.47-1.58 2.71.16 1.1 1.1 1.99 2.2 2.11.47.05.88-.03 1.27-.2v8.21h-2V20h-3v-9H12v18h10v-7.5h1.5v7.49h5V17c0-.69-.28-1.32-.73-1.77zM20 18h-6v-5h6v5zm5-1c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z\" fill=\"#0C0C0C\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});