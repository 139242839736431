define("ember-svg-jar/inlined/telemetry-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.195 31.71c-6.493-6.493-6.493-17.02 0-23.515 6.412-6.411 16.756-6.491 23.268-.241l-2.475 2.237c-5.22-4.835-13.368-4.718-18.441.356-5.196 5.196-5.196 13.617 0 18.812s13.617 5.196 18.812 0a13.242 13.242 0 003.794-7.76h-3.357a9.977 9.977 0 11-3.286-9.165l-2.494 2.255A6.651 6.651 0 1026.4 21.6h-3.554a3.326 3.326 0 110-3.289h10.31l3.345-.016a16.591 16.591 0 01-4.79 13.417c-6.492 6.494-17.02 6.494-23.515 0z\"/>",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
  _exports.default = _default;
});